import * as HELPER from '../../helpers.js';
import popupWinInCenter from './popupWinInCenter.js';
import { initXlsx, downloadXlsx } from './download-xlsx.js';

const isTouch = () => window.matchMedia("(max-width: 1023px)").matches;

export default function enableNav(DataSource) {
	document.querySelectorAll('.navbar-end .buttons[disabled]').forEach(el => el.removeAttribute('disabled') );

	document.getElementById("open-seatmap").addEventListener("click", (event)=>{
		const btn = document.getElementById("open-seatmap");
		if(isTouch()) {
			const modal = document.getElementById('modal-seatmap');
			modal.querySelector('img').src = btn.href;
			modal.classList.add('is-active');
		} else {
			popupWinInCenter(btn.href, 'seatmap', 1034, 778);
		}
		event.preventDefault();
	}, false);

	document.getElementById("download-xlsx").addEventListener("click", ()=>{
		document.getElementById("download-pending").classList.remove('is-invisible');
		window.setTimeout(()=>downloadXlsx(DataSource), 500);
		window.setTimeout(()=>document.getElementById("download-pending").classList.add('is-invisible'), 3500);
	});

	document.getElementById("share-url").addEventListener("click", ()=>{
		if(navigator.share) {
			navigator.share({ title:document.title, text:'', url:window.location.href });
		} else {
			const elem = document.getElementById("share-url-success");
			elem.classList.remove('is-invisible');
			window.setTimeout(()=> elem.classList.add('is-invisible'), 3000);
		}
	});
	
	setDataRefreshTs(DataSource.json.ts);
	setTimeout(initXlsx, 500);
}

const setDataRefreshTs = (jsonTs, updateText=true) => {
	const refreshTs = new Date(jsonTs);
	const refreshElem = document.getElementById('last-refreshed');

	if(updateText) {
		const isRefreshTsToday = (refreshTs.toLocaleString('en-us', {dateStyle:'short'}) === (new Date()).toLocaleString('en-us', {dateStyle:'short'}));
		refreshElem.innerHTML = `refreshed 
			${(isRefreshTsToday ? '' : ' on '+refreshTs.toLocaleString('en-us', { dateStyle:'short' }))}
			at ${refreshTs.toLocaleString('en-us', { timeStyle:'short' })}`;
	}

	const TTL = 15; //minutes
	const dateStaleInMin = Math.round((Date.now() - refreshTs) / HELPER.MIN_IN_MS);
	if(dateStaleInMin > TTL) {
		refreshElem.classList.add('has-text-danger');
		document.querySelector(".navbar-burger .dot").classList.remove('is-hidden');
		document.getElementById('btn-refresh').classList.remove('is-hidden');
	} else {
		const nextRun = Math.max(HELPER.MIN_IN_MS, (jsonTs + (TTL*HELPER.MIN_IN_MS)) - Date.now());
		setTimeout(()=>setDataRefreshTs(jsonTs, false), nextRun);
	}
}