import * as MAP from '../../maps.js';
import * as HELPER from '../../helpers.js';

/**
 * CheckCalendar
 * 
 * To use, simple instanciate the object
 * eg: new CheckCalendar('calendar', [1705351976, 1705438376, 1705506777]);
 * 
 * Be sure to import the base stylesheet
 */
export default class CheckCalendar {
    #wrapperElem;
    #OPTS = {
        startOnFirstOfCurrMonth: false, // used if next game is in current month, otherwise always start on 1st
        noDatesCopy: 'No dates available',
        inputFormat: (date) => date.toLocaleDateString('en-us', { weekday:'short', month:'short', day:'2-digit' }),
        checkedCallback: null, //(checkboxElem)=>{ ... }
        locale: navigator.language,
        IntlLocale: null,
        daysOfTheWeek: null
    };

    /**
     * 
     * @param {String} wrapperElemId 
     * @param {Array[Date]} dates 
     * @param {Object} opts 
     */
    constructor(wrapperElemId, dates, opts={}) {
        this.#wrapperElem = document.getElementById(wrapperElemId);
        this.#initOpts(opts);
        
        const today = new Date();
        today.setHours(0,0,0,0);

        let start;

        const nextGame = dates.at(0).start;
        if(today.getFullYear()===nextGame.getFullYear() && today.getMonth()===nextGame.getMonth()) {
            start = this.#OPTS.startOnFirstOfCurrMonth ? new Date(today.getFullYear(), today.getMonth(), 1) : today;
        } else {
            const firstDate = nextGame;
            start = new Date(nextGame.getFullYear(), nextGame.getMonth(), 1);
        }

        const lastDate = dates.at(-1).start;
        const end = new Date(lastDate.getFullYear(), lastDate.getMonth()+1, 0);
        const numOfDays = Math.round((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
    
        const dl = document.createElement('dl');
        let dd;
    
        for(let i=0; i<numOfDays; i++) {
            let curr = new Date(start.getFullYear(), start.getMonth(), start.getDate()+i);
            let box;
            
            let currGame = dates.find(game => game.start.toLocaleDateString(this.#OPTS.locale) === curr.toLocaleDateString(this.#OPTS.locale));
            if(currGame) {
                box = document.createElement('time');
                box.setAttribute('datetime', curr.toJSON().substring(0, 10));
                if(currGame.type === MAP.GAME_TYPE.PRESEASON.TEXT) {
                    box.classList.add('is-preseason');
                    box.setAttribute('title', currGame.type);
                }
                if(currGame.type === MAP.GAME_TYPE.PLAYOFFS.TEXT || currGame.type === MAP.GAME_TYPE.PLAYOFFS_TBD.TEXT) {
                    box.classList.add('is-playoffs');
                    box.setAttribute('title', currGame.type);
                }
                box.innerHTML = `<label class="checkbox"><input type="checkbox" name="date" value="${this.#OPTS.inputFormat(curr)}"><span>${curr.toLocaleDateString(this.#OPTS.locale, { day:'numeric' })}</span></label>`;
            } else {
                box = document.createElement('span');
            }
    
            if(i === 0 || curr.getDate() == 1) {
                box.style.cssText += 'grid-column-start:' + (curr.getDay() + ((7 - this.#OPTS.IntlLocale.weekInfo.firstDay + 1) % 7) % 7);

                if(dd) dl.appendChild(dd);
                dd = document.createElement('dd');

                this.#addMonthHeader(dl, curr);
                this.#addDayOfWeekHeader(dd);
            }
    
            this.#decorateDay(box, today, curr);
            dd.appendChild(box);
        }
    
        this.#wrapperElem.classList.add('check-calendar');
        if(dd) {
            dl.appendChild(dd);
            this.#wrapperElem.appendChild(dl);
        } else {
            const span = document.createElement('span');
            span.innerText = this.#OPTS.noDatesCopy;
            span.classList.add('no-dates-available');
            this.#wrapperElem.appendChild(span);
        }

        this.#attachChangeEventListeners();
    }

    /**
     * Merge construct opts with default opts.
     * 
     * @param {Object} opts 
     */
    #initOpts(opts) {
        this.#OPTS = { ...this.#OPTS, ...opts };
        this.#OPTS.IntlLocale = new Intl.Locale(this.#OPTS.locale);

        // defaults to en-US for lack of available polyfill
        if(!this.#OPTS.IntlLocale.weekInfo) {
            this.#OPTS.IntlLocale.weekInfo = { firstDay:7, weekend:[6,7] };
        }

        if(!this.#OPTS.daysOfTheWeek) {
            this.#OPTS.daysOfTheWeek = Array(7).fill(new Date()).map((d,i) => new Date(d.setDate(d.getDate()-d.getDay()+i)).toLocaleDateString(this.#OPTS.locale, { weekday:"narrow" }));
        }
    }

    /**
     * Add a row before the calendar to show days of the week
     * 
     * @param {Element} parent 
     */
    #addDayOfWeekHeader(parent) {
        this.#OPTS.daysOfTheWeek.forEach(day => {
            const header = document.createElement('span');
            header.setAttribute('aria-hidden', 'true');
            header.classList.add('is-header');
            header.innerHTML = day;
            parent.appendChild(header);
        });
    }

    /**
     * Add the current month name before the calendar
     * 
     * @param {Element} parent 
     * @param {Date} currDate 
     */
    #addMonthHeader(parent, currDate) {
        const dt = document.createElement('dt');
        dt.innerText = currDate.toLocaleDateString(this.#OPTS.locale, { month:'long' }); //year:'numeric'
        parent.appendChild(dt);
    }

    /**
     * Add class names, etc to current day element
     * 
     * @param {Element} elem 
     * @param {Date} today 
     * @param {Date} currDate 
     */
    #decorateDay(elem, today, currDate) {
        elem.classList.add('is-day');

        if(elem.localName !== 'time') {
            elem.setAttribute('aria-hidden', 'true');
            elem.classList.add('is-empty');
        }

        const dayOfWeek = currDate.getDay() || 7; //if 0, then switch to 7;
        if(this.#OPTS.IntlLocale.weekInfo.weekend.includes(dayOfWeek)) {
            elem.classList.add('is-weekend');
        }

        if(today > currDate) {
            elem.classList.add('is-past');
        } else if(today.getTime()===currDate.getTime()) {
            elem.classList.add('is-today');
        }
    }

    /**
     * Added "change" (eg, onclick) callback, if set
     */
    #attachChangeEventListeners() {
        if(this.#OPTS.checkedCallback) {
            document.querySelectorAll('.check-calendar dd input').forEach(elem => {
                elem.addEventListener('change', (evt)=>this.#OPTS.checkedCallback(elem));
            });
        }
    }

}


