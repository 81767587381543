import initGlobalAddons from './global-addons.js';
import DataSource from './data-source.js';
import generateFilterUI from './filters-ui.js';
import initTutorial from './tutorial-ui.js';
import enableNav from './nav-ui.js';
import { populateTable, initBookmarks, getUrlParamFilters } from './offer-table-ui.js'

/**
 * RUN
 */
await DataSource.load(); //this must be first
generateFilterUI(DataSource, populateTable);
populateTable(0, getUrlParamFilters(window.location));
initGlobalAddons();
enableNav(DataSource);
initTutorial();
initBookmarks(DataSource);
