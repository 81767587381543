import * as CONFIG from '../../configs.js';
import HeadersSource from './source.js';

export default class HeadersR2 {

    /**
     * Get list
     * 
     * @param {Object} env
     * @param {String} format (options: stream, arrayBuffer, text, json)
     * @returns {ReadableStream|Promise<ArrayBuffer>|Promise<String>|Promise<T>|null}
     */
    static async get(env, format='json') {
        const get = await env.BUCKET.get(CONFIG.FILENAME.HEADERS);
        if(!get) return null;

        // ordered from fastest to slowest
        switch(format) {
            case 'stream':
                return get.body;
            case 'arrayBuffer':
                return get.arrayBuffer();
            case 'text':
                return get.text();
            case 'json':
            default:
                return get.json();
        }
    }

    /**
     * Save new data
     * 
     * @param {*} env 
     * @param {Array} headers 
     * @returns {Promise}
     */
    static async save(env, headers) {
        const ver = 1;
        const ts = Date.now();
        return env.BUCKET.put(CONFIG.FILENAME.HEADERS, JSON.stringify({
            ver,
            ts,
            cnt: headers.length,
            headers
        }), {
            customMetadata: { ver, ts },
            httpMetadata: { contentType:'application/json' }
        });
    }

    /**
     * Delete data
     * 
     * @param {Object} env
     * @returns {Promise}
     */
    static async delete(env) {
        return env.BUCKET.delete(CONFIG.FILENAME.HEADERS);
    }

}