import * as CONFIG from '../../configs.js';
import * as MAP from '../../maps.js';
import * as HELPER from '../../helpers.js';
import HeadersController from '../../controllers/headers.js';
import GamesMap, { IDX as IDXG } from './map.js';

export default class GamesSource {

    /**
     * Get list of games
     * 
     * @param {Object} env
     * @param {Number} ts
     * @returns {GamesMap}
     */
    static async refresh(env, ts) {
        const softMatchStartTolerance = HELPER.DAY_IN_MS/6; // 4 hours
        const gamesMap = new GamesMap();

        let [nhlGames, tickpickGames] = (await Promise.allSettled([
            this._getGamesFromNhl(env),
            this._getGamesFromTickPick(env)
        ])).map(result => result.value || []);

        tickpickGames.forEach(game => {
            let matchedNhlGame = nhlGames.find(nhlGame => Math.abs(nhlGame.start - game[IDXG.start]) < softMatchStartTolerance);
            game[IDXG.season] = matchedNhlGame ? matchedNhlGame.season : game[IDXG.season];
            game[IDXG.type] = matchedNhlGame ? matchedNhlGame.game_type : game[IDXG.type];
            game[IDXG.opponent_abbrev] = matchedNhlGame ? matchedNhlGame.opponent_abbrev : game[IDXG.opponent];
            game[IDXG.opponent_logo] = matchedNhlGame ? matchedNhlGame.opponent_logo : CONFIG.HARDCODE.NHL.DEFAULT_LOGO;
            game[IDXG.updated_at] = ts;
            game[IDXG.history] = [];
            gamesMap.add(game);
        });

        return gamesMap;
    }

    static async _getGamesFromTickPick(env) {
        let url = 'https://api.tickpick.com/1.0/workers/performers?' + new URLSearchParams({
            offset: '0',
            limit: '50',
            includeEventsOnly: 'true',
            performerName: 'Boston Bruins',
            lat: '42.36500',
            long: '-71.06928',
            homeOrAway: 'home'
        });
        let opts = JSON.parse(JSON.stringify(CONFIG.FETCH_OPTS.TICKPICK));
        if(env) {
            opts.headers = await HeadersController.getRandom(env, opts.headers);
        }

        const data = await HELPER.fetchRespJSON(url, opts, 'Tickpick game list');
        
        let games = [];
        if(data && data.hasOwnProperty('events')) {
            for(let i=0; i<data.events.length; i++) {
                let awayTeam = data.events[i].secondary_performers.find(p=>p.role=='Away Team');
                if(!awayTeam) awayTeam = data.events[i].secondary_performers[0];
                
                const startDate = new Date(data.events[i].event_date_utc+"Z");

                let game = new Array(Object.keys(IDXG).length);
                game[IDXG.id]               = i;
                game[IDXG.tickpick_id]      = HELPER.toNumberIfNumber(data.events[i].event_id);
                game[IDXG.opponent]         = awayTeam.name;
                game[IDXG.start]            = startDate.getTime();
                game[IDXG.opponent_short]   = awayTeam.short_name;

                // This is a fallback. Hopefully, overwritten in refresh()
                switch (awayTeam.performer_id) {
                    case CONFIG.HARDCODE.TICKPICK.PERFORMER_ID.PRESEASON:
                        game[IDXG.type] = MAP.GAME_TYPE.PRESEASON.ID;
                        break;
                    case CONFIG.HARDCODE.TICKPICK.PERFORMER_ID.EASTERN_CONFERENCE_FIRST_ROUND:
                    case CONFIG.HARDCODE.TICKPICK.PERFORMER_ID.EASTERN_CONFERENCE_SECOND_ROUND:
                    case CONFIG.HARDCODE.TICKPICK.PERFORMER_ID.CONFERENCE_FINALS:
                    case CONFIG.HARDCODE.TICKPICK.PERFORMER_ID.PLAYOFFS:
                        game[IDXG.type] = MAP.GAME_TYPE.PLAYOFFS_TBD.ID;
                        break;
                    default:
                        game[IDXG.type] = MAP.GAME_TYPE.UNKNOWN.ID;
                }
                if(game[IDXG.type] === MAP.GAME_TYPE.UNKNOWN.ID) {
                    if(data.events[i].description.toLowerCase().includes('preseason')) {
                        game[IDXG.type] = MAP.GAME_TYPE.PRESEASON.ID;
                    }
                }

                // This is a fallback. Hopefully, overwritten in refresh()
                const startOfSeasonYear = (startDate.getMonth()+1 <= 6)
                    ? startDate.getFullYear() - 1
                    : startDate.getFullYear();
                game[IDXG.season] = (''+startOfSeasonYear).substring(2, 4) * 1;

                games.push(game);
            }
        }

        return games;
    }

    /**
     * Get game data from NHL API
     * 
     * @todo move 'BOS' compare to config
     * @returns {Array}
     */
    static async _getGamesFromNhl(env) {
        if(!env) return [];

        const bostonTeamAbbrev = 'BOS';
        const url = 'https://api-web.nhle.com/v1/club-schedule-season/bos/now';

        let opts = JSON.parse(JSON.stringify(CONFIG.FETCH_OPTS.NHLE));
        opts.headers = await HeadersController.getRandom(env, opts.headers);
        let data = await HELPER.fetchRespJSON(url, opts, 'NHL game list');

        let games = [];
        if(data && data.hasOwnProperty('games')) {
            const homeGames = data.games.filter(game=>game.homeTeam.abbrev==bostonTeamAbbrev);
            for(let i=0; i<homeGames.length; i++) {
                games.push({
                    start:              new Date(homeGames[i].startTimeUTC).getTime(),
                    season:             (''+homeGames[i].season).substring(2,4) * 1,
                    game_type:          homeGames[i].gameType,
                    opponent_abbrev:    homeGames[i].awayTeam.abbrev,
                    opponent_logo:      homeGames[i].awayTeam.logo
                });
            }
        }

        return games;
    }

}