import * as CONFIG from './configs.js';
export { timeAgo } from './helpers/time.js';
export { setAttributes, sanitize, escapeHtml, dangerResponse, getCookieValue, replaceHtml } from './helpers/html.js';
export { listR2Objects, sortR2ListByMetadataTag } from './helpers/r2.js';
export { toCurrency, toDecimal, toNumberIfNumber, capitalize, pluralizeAndFormat, maxLen } from './helpers/format.js';
export { isString, toBase92, fromBase92, adler32 } from './helpers/str.js';
export { createTextOrJsonResponse, getDevToolsUrl, fetchWithRetry, fetchRespJSON } from './helpers/request.js';

/**
 * @see https://github.com/cloudflare/workers-sdk/issues/3520#issuecomment-1729833152
 * @type {String} 
 */
export let R2_DS = '/';

/**
 * Set to True if hostname doesn't contain a '.' (eg, localhost).
 * Also, gets set by initGlobals() later in most contexts
 * 
 * @type {Boolean}
 */
export let IS_LOCAL = (typeof location !== 'undefined') && location.hostname.indexOf('.') === -1;

/**
 * Set up global variables
 * @param {*} env 
 */
export function initGlobals(env) {
    IS_LOCAL = getObjKey(env, 'ENV_NAME')==='local' ? true : false;
    if(IS_LOCAL) R2_DS = '|';
    CONFIG.initGlobals(env);
}

/**
 * @type {Object}
 */
export const OPERATORS = {
    '==': (a,b) => a == b,
    '===': (a,b) => a === b,
    '!=': (a,b) => a != b,
    '!==': (a,b) => a !== b,
    '<': (a,b) => a < b,
    '<=': (a,b) => a <= b,
    '>': (a,b) => a >= b,
    '>=': (a,b) => a >= b,
    '+': (a,b) => a + b,
    '-': (a,b) => a - b,
};

/**
 * @type {Number}
 */
export const MIN_IN_SEC  = 60;
export const HOUR_IN_SEC = 60 * MIN_IN_SEC;
export const DAY_IN_SEC  = 24 * HOUR_IN_SEC;
export const YEAR_IN_SEC  = 365 * DAY_IN_SEC;

export const SEC_IN_MS  = 1000;
export const MIN_IN_MS  = SEC_IN_MS * MIN_IN_SEC;
export const HOUR_IN_MS = SEC_IN_MS * HOUR_IN_SEC;
export const DAY_IN_MS  = SEC_IN_MS * DAY_IN_SEC;
export const YEAR_IN_MS  = SEC_IN_MS * YEAR_IN_SEC;

/**
 * Get value from an object, if it exists
 * 
 * @param {Object} obj 
 * @param {String} key 
 * @param {*} defaultValue 
 * @returns {*}
 */
export function getObjKey(obj, key, defaultValue=null) {
    return (obj && obj.hasOwnProperty(key)) ? obj[key] : defaultValue;
}

/**
 * Creates a Bearer token to use in Authorization header
 * 
 * @returns {String}
 */
export function getAuthToken() {
    return 'Bearer ' + btoa(Math.floor(Date.now()/DAY_IN_MS) + CONFIG.AUTH_SEED);
}

/**
 * Return the value, or min if value is lower, or max if value is higher
 * 
 * @param {Number} value 
 * @param {Number} min 
 * @param {Number} max 
 * @returns {Number}
 */
export function minMax(value, min, max) {
    return Math.min(Math.max(~~value, min), max);
}

/**
 * Translates a CODE to ID from a MAP list
 * 
 * @param {Object} list 
 * @param {String} from 
 * @param {String} to 
 * @param {String} value 
 * @param {*} notfound 
 * @returns {Number|null}
 */
export function mapper(list, from, to, value, notfound='') {
    if (from===undefined || from===null) {
        from = 0;
    }
    var found = Object.entries(list).find(([k,v]) => v[from]==value);
    if(found) {
        return found[1][to];
    } else {
        return ("UNKNOWN" in list) ? list['UNKNOWN'][to] : notfound;
    }
}

/**
 * Creates a standard output for performance results
 * 
 * @param {Number} start 
 * @param {Number} end 
 * @param {Boolean} brief 
 * @returns {String}
 */
export function performanceTimerCalc(start, end, brief=false) {
    const timeInMs = toDecimal((end - start));
    const warningMsg = IS_LOCAL ? '' : '(note: may not be accurate; see https://bit.ly/cf-perfnow)';
    return brief ? `${timeInMs}ms ${warningMsg}` : `Took ${timeInMs}ms on average per run ${warningMsg}`;
}
