import tippy from 'tippy.js';

export default function initTutorial() {
	const namespace = 'tutorial';
	if(!sessionStorage.getItem(namespace)) {
		sessionStorage.setItem(namespace, 1);
		const elem = document.getElementById('btn-filters-open');
		const filterTutorial = tippy(elem, { content:'Try a filter!', trigger:'click', animation:'shift-toward-extreme' });
		filterTutorial.show();
		elem.addEventListener('click', filterTutorial.destroy, { once:true });
	}
}