import * as CONFIG from '../../configs.js';
import * as HELPER from '../../helpers.js';
import * as MAP from '../../maps.js';
import RangeSliderPips from 'svelte-range-slider-pips';
import CheckCalendar from './check-calendar.js';
import { populateTable } from './offer-table-ui.js'

export default function generateFilterUI(DataSource, populateTableDelayed) {

	/**
	 * DAY
	 */
	let allDaysOfTheWeek = DataSource.getAllDaysOfTheWeek();
	let daysOfTheWeek = DataSource.getUniqueValues('day');
	document.getElementById('filter-day').innerHTML += 
		allDaysOfTheWeek.map(day => {
			if(daysOfTheWeek.includes(day)) {
				return `<label class="button is-responsive"><input type="checkbox" name="day" value="${day}" class="is-hidden">${day}</label>`;
			} else {
				return `<button class="button is-responsive" disabled>${day}</button>`;
			}
		}).join('\n');
	
	/**
	 * SEASON (Game Type)
	 */
	let gameTypes = DataSource.getUniqueValues('type');
	document.getElementById('filter-season').innerHTML += 
	gameTypes.map(type => `<label class="button is-responsive"><input type="checkbox" name="type" value="${type}" class="is-hidden">${type}</label>`).join('\n');
	if(gameTypes.length === 1) {
		document.getElementById('filter-season').parentElement.classList.add('is-hidden');
	}
	
	/**
	 * MONTH
	 */
	let monthsOfTheYear = DataSource.getUniqueValues('month');
	document.getElementById('filter-month').innerHTML += 
		monthsOfTheYear.map(month => `<label class="button is-responsive"><input type="checkbox" name="month" value="${month}" class="is-hidden">${month}</label>`).join('\n');
	if(monthsOfTheYear.length > 7) {
		document.getElementById('filter-month').classList.add('is-narrow');
	}
	
	/**
	 * HOUR
	 */
	let startHours = DataSource.getUniqueValues('hour', {
		sortFn: (a,b) => a.hour.replace('12','0').split(' ').reverse().join().localeCompare(b.hour.replace('12','0').split(' ').reverse().join())
	});
	document.getElementById('filter-hour').innerHTML += 
		startHours.map(hour => `<label class="button is-responsive"><input type="checkbox" name="hour" value="${hour}" class="is-hidden">${hour}</label>`).join('\n');

	/**
	 * DATE
	 */
	let dates = DataSource.getUniqueValues('start', { formatFn:val=>new Date(val), addlFields:['type'] });
	new CheckCalendar('filter-date', dates);

	/**
	 * RATING
	 */
	let ratings = DataSource.getUniqueValues('rating', { sortFn:true });
	document.getElementById('filter-rating').innerHTML += 
		ratings.map(rating => `<label class="button is-responsive"><input type="checkbox" name="rating" value="${rating}" class="is-hidden">${rating}</label>`).join('\n');

	/**
	 * SIDE
	 */
	let sides = DataSource.getUniqueValues('side', { sortFn:true });
	document.getElementById('filter-side').innerHTML += 
		sides.map(side => `<label class="button is-responsive"><input type="checkbox" name="side" value="${side}" class="is-hidden">${side}</label>`).join('\n');

	/**
	 * LEVEL
	 */
	let levels = DataSource.getUniqueValues('level', { sortFn:true, filterFn:row=>row.level!==MAP.LEVEL.UNKNOWN.TEXT });
	document.getElementById('filter-level').innerHTML += 
		levels.map(level => `<label class="button is-responsive"><input type="checkbox" name="level" value="${level}" class="is-hidden">${level}</label>`).join('\n');

	/**
	 * ACCESS
	 */
	let access = DataSource.getUniqueValues('access', { sortFn:true });
	document.getElementById('filter-access').innerHTML += '<select name="access">' + access.map(access => `<option value="${access}">${access}</option>`).join('\n') + '</select>';

	/**
	 * OPPONENTS
	 */
	let opponents = DataSource.getUniqueValues('opponent', { sortFn:true, addlFields:['opponent_logo'] });
	document.getElementById('filter-opponent').innerHTML += 
		opponents.map(o => `<label class="checkbox panel-block"><input type="checkbox" name="opponent" value="${o.opponent}"><img src="${o.opponent_logo}" alt="${o.opponent} Logo" class="nhl-logo is-unselectable" referrerpolicy="no-referrer"><span>${o.opponent}</span></label>`).join('\n');

	/**
	 * PRICE
	 */
	document.getElementById("price-slider").innerHTML = '<input type="hidden" name="price" min="0" max="1000" step="10">';
	let priceInput = document.querySelector("#price-slider input");
	let pAttr = (attr) => parseInt(priceInput.getAttribute(attr), 10);
	let priceSlider = new RangeSliderPips({ target:priceInput.parentElement, props: {
		range:'min', pips:true, pipstep:20, all:'label', float:true, prefix:'$', step:pAttr('step'), min:pAttr('min'), max:pAttr('max')
	}});
	priceSlider.$on('stop', (e)=> {
		if(priceInput.value != e.detail.values[0]) {
			priceInput.value = e.detail.values[0];
			priceInput.dispatchEvent(new Event('change'));
		}
	});
	priceInput.addEventListener('change', (e) => {
		priceSlider.$set({ values:[ priceInput.value ]});
	});
	priceInput.classList.add('is-hidden');

	/**
	 * QTY
	 */
	document.getElementById("qty-slider").innerHTML = `<input type="input" name="qty" maxlength="5" placeholder="2-${CONFIG.UI.FILTER.QTY_MAX}">`;
	let qtyInput = document.querySelector("#qty-slider input");
	let qAttr = (attr) => parseInt(qtyInput.getAttribute(attr), 10);
	let qtySlider = new RangeSliderPips({ target:qtyInput.parentElement, props: {
		range:true, pushy:true, pips:true, pipstep:1, all:'label', float:true, step:1, min:1, max:CONFIG.UI.FILTER.QTY_MAX
	}});
	qtySlider.$on('stop', (e) => {
		let valueTemplate = `${e.detail.values[0]}${CONFIG.UI.URL.PARAM_SEPARATOR.QTY}${e.detail.values[1]}`;
		if(qtyInput.value != valueTemplate) {
			qtyInput.value = valueTemplate;
			qtyInput.dispatchEvent(new Event('change'));
		}
	});
	qtyInput.addEventListener('change', (e) => {
		qtySlider.$set({ values:qtyInput.value.split(CONFIG.UI.URL.PARAM_SEPARATOR.QTY) });
	});
	qtyInput.classList.add('is-hidden');

	/**
	 * SECTION, ROW, SEATS
	 */
	HELPER.setAttributes('.navbar input[type=text], .navbar input[type=number]', { spellcheck:false , autocorrect:'off', autocomplete:'off' });

	/**
	 * EVENT LISTENERS
	 * 
	 * Only attach these events after the initial table load so it doesn't happen twice
	 */
	document.addEventListener('OfferTableContentChanged', ()=>{
		document.querySelectorAll('#filters input, #filters select').forEach(input => {
			input.addEventListener('change', ()=>populateTableDelayed(100));
		});
		document.querySelectorAll('#filters input[type=text], #filters input[type=number]').forEach(input => {
			input.addEventListener('keyup', ()=>populateTableDelayed(250));
		});
	}, { once:true });

	/**
	 * ENABLE NAV BUTTONS
	 */
	document.querySelectorAll('.navbar.is-fixed-bottom button:not(.btn-no-auto-enable)').forEach(el => el.removeAttribute('disabled') );
	
	document.getElementById('btn-filters-clear').addEventListener("click", ()=>{
		populateTable(0, CONFIG.UI.FILTER.DEFAULTS);
	});

}