import getColumnsDefs from './column-defs.js';

export function initXlsx() {
	var s = document.createElement('script');
	s.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.18.5/xlsx.full.min.js');
	s.setAttribute('async', '');
	s.setAttribute('crossorigin', 'anonymous');
	s.setAttribute('referrerpolicy', 'no-referrer');
	s.setAttribute('fetchpriority', 'low');
	document.head.appendChild(s);
}

export function downloadXlsx(DataSource) {
	let datetime = new Date();    
	datetime = new Date(datetime.getTime() - datetime.getTimezoneOffset() * 60000);
	datetime = datetime.toJSON().slice(0,19).replace(/\D/g,'');

	const columnsDefs = getColumnsDefs(DataSource.json);
	const headers = columnsDefs.filter(def => !def.skipExport).map(def => def.title);
	const data = [];

	DataSource.offerData.forEach(offer => {
		const row = {};
		columnsDefs.forEach(def => {
			if(!def.skipExport) row[def.field] = offer[def.field];
		});
		data.push(row);
	});

	const worksheet = XLSX.utils.json_to_sheet(data);
	const workbook = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(workbook, worksheet, "Tickets");
	XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
	XLSX.writeFile(workbook, `tickets-${datetime}.xlsx`, { compression: true });
}