import * as CONFIG from '../../configs.js';
import * as HELPER from '../../helpers.js';
import * as MAP from '../../maps.js';
import { IDX as IDXG } from '../../models/games/map.js';
import getVrSeatViewUrl from './vr-seat-view.js';

const allVisibilityExcept = (texts) => Object.values(MAP.ACCESS).map(a=>a.TEXT).filter(text => ![].concat(texts).includes(text));

export default function getColumnsDefs(json) {
    return [
        {
            title: "ID",
            field: "id",
            visibility: false,
        },
        {
            title: "Access To",
            field: "access",
            visibility: false,
        },
        {
            title: "Season",
            field: "type",
            visibility: !json.games.every(game => game[IDXG.type]==json.games[0][IDXG.type]),
        },
        {
            title: "Date",
            field: "date",
            align: "right",
            filterReps: ["day", "month"]
        },
        {
            title: "Time",
            field: "time",
            align: "right",
            filterReps: ["hour"]
        },
        {
            title: "Opponent",
            field: "opponent",
            formatter: (val, offer) => {
                if(offer.opponent_logo) {
                    return `<div class="is-flex"><img src="${offer.opponent_logo}" alt="${val} Logo" class="nhl-logo is-unselectable" referrerpolicy="no-referrer"><span>${val}</span></div>`;
                } else {
                    return val;
                }
            }
        },
        {
            title: "Total Qty",
            field: "qty",
            align: "right",
            visibility: false,
        },
        {
            title: "Qty",
            field: "splits",
            align: "right",
            headerTooltip: "Available quantities to purchase. Eg, \"2, 4-6\" means it can be purchased in quantities of 2, 4, 5, or 6 (not 1 or 3).",
            formatter: (val, offer) => {
                let rangeStart;
                let final = [];
                for(let i=0; i<val.length; i++) {
                    let curr = val[i];
                    let next = val[i+1]; // purposefully, underfined during last loop
                    if(!rangeStart) {
                        rangeStart = curr
                    };
                    if(curr+1 !== next) {
                        final.push(rangeStart + (rangeStart!==curr ? '-'+curr : ''));
                        rangeStart = null;
                    }
                }
                return final.join(', ');
            }
        },
        {
            title: "Price",
            title_short: "$",
            field: "price",
            align: "right",
            icons: ["fa-arrow-down-short-wide"],
            formatter: (val) => HELPER.toCurrency(val)
        },
        {
            title: "Rating",
            field: "rating",
            align: "centered",
            visibility: MAP.ACCESS.EVENT.TEXT,
            headerTooltip: "Rating of seat view. See seat map."
        },
        {
            title: "Side",
            field: "side",
            visibility: MAP.ACCESS.EVENT.TEXT,
        },
        {
            title: "Level",
            field: "level",
            visibility: MAP.ACCESS.EVENT.TEXT,
        },
        {
            title: "Section",
            field: "section_name",
            formatter: (val, offer) => {
                if(offer.access === MAP.ACCESS.EVENT.TEXT) {
                    let str = `<span class="icon-text is-flex is-flex-wrap-nowrap"><span class="is-flex-grow-1">${val}</span>`;

                    if((1 <= offer.section && offer.section <= 21) || (301 <= offer.section && offer.section <= 330)) {
                        str += `<span class="icon mx-1" data-seatview-tooltip="${offer.section}"><i class="far fa-camera"></i></span>`;
                    } else if(offer.access === MAP.ACCESS.EVENT.TEXT) {
                        str += `<span class="icon mx-1 has-text-grey-light"><i class="far fa-camera-slash"></i></span>`;
                    }

                    const vrSeatViewUrl = getVrSeatViewUrl(offer);
                    if(vrSeatViewUrl) {
                        const iframe = `<iframe src="${vrSeatViewUrl}" credentialless="true" referrerpolicy="no-referrer" scrolling="no" style="border:0; overflow:hidden; width:100%; height:100%;"></iframe>`;
                        str += `<span class="icon mx-0 is-clickable js-modal-trigger" data-target="modal-vr-seat-view" data-copy="${HELPER.escapeHtml(iframe)}"><span class="fa-stack new-tab"><i class="far fa-street-view"></i><i class="far fa-arrow-up-right"></i></span></span>`;
                    } else {
                        str += `<span class="icon mx-0 has-text-grey-light"><span class="fa-stack"><i class="far fa-street-view fa-stack-1x"></i><i class="far fa-slash fa-stack-1x"></i></span></span>`;
                    }

                    return str + '</span>';
                } else {
                    return val.replace(/(\W*NOT AN EVENT TICKET)/, '');
                }
            }
        },
        {
            title: "Row",
            field: "row",
            align: "right",
            visibility: MAP.ACCESS.EVENT.TEXT,
        },
        {
            title: "Seats",
            field: "",
            align: "right",
            headerTooltip: "If empty, only shown after purchase.",
            formatter: (val, offer) => `<span title="${offer.seat_from} - ${offer.seat_to}">${HELPER.maxLen(offer.seat_from, 3)} - ${HELPER.maxLen(offer.seat_to, 3)}</span>`,
            skipExport: true,
            filterReps: ['seat_from', 'seat_to'],
            visibility: MAP.ACCESS.EVENT.TEXT,
        },
        {
            title: "First Seat",
            field: "seat_from",
            align: "right",
            headerTooltip: "First seat number. If empty, only shown after purchase.",
            formatter: (val) => `<span title="${val}">${HELPER.maxLen(val, 3)}</span>`,
            visibility: false,
        },
        {
            title: "Last Seat",
            field: "seat_to",
            align: "right",
            headerTooltip: "Last seat number. If empty, only shown after purchase.",
            formatter: (val) => `<span title="${val}">${HELPER.maxLen(val, 3)}</span>`,
            visibility: false,
        },
        {
            title: "Disclosure",
            field: "disclosure",
            visibility: [MAP.ACCESS.PARKING.TEXT, MAP.ACCESS.LOUNGE.TEXT],
            formatter: (val, offer) => [MAP.ACCESS.PARKING.TEXT, MAP.ACCESS.LOUNGE.TEXT].includes(offer.access) ? 'Not an event ticket' : '',
        },
        {
            title: "Link",
            field: "link",
            visibility: false,
        },
        {
            title: "Buy",
            field: "link",
            formatter: (val, offer) => {
                let warnings = [];
                if(offer.type === MAP.GAME_TYPE.PLAYOFFS_TBD.TEXT) {
                    warnings.push(`This game is NOT officially scheduled yet. The game will only be played if necessary and the date and time may change. Additional notices may be <a class="is-normalcase" href="https://www.tickpick.com/buy-tickets/${offer.tickpick_game_id}/" target="_blank" referrerpolicy="no-referrer" rel="noopener noreferrer">available</a>.`);
                }
                if(offer.access !== MAP.ACCESS.EVENT.TEXT) {
                    warnings.push('This ticket does NOT get you into the game. You must purchase an additional ticket. See seller note at checkout.');
                }

                if(warnings.length) {
                    return `<button class="button is-ghost is-small has-text-info is-underlineless is-underlined-hover is-fullwidth js-modal-trigger" data-target="modal-checkout-warning" data-copy="${HELPER.escapeHtml(warnings.join('<br><br>'))}" data-targetlink="${val}"><span class="icon is-small"><i class="far fa-shopping-cart" title="Buy on TickPick"></i></span><span>Buy</span></button>`
                } else {
                    return `<a href="${val}" referrerpolicy="no-referrer" rel="noopener noreferrer" target="_blank" class="button is-ghost is-small has-text-info is-underlineless is-underlined-hover is-fullwidth"><span class="icon is-small"><i class="far fa-shopping-cart" title="Buy on TickPick"></i></span><span>Buy</span></a>`
                }
            },
            align: "centered",
            classes: ['p-0'],
            skipExport: true,
        }
    ];
}