import * as HELPER from './helpers.js';

/**
 * Set up global variables
 * @param {*} env 
 */
export function initGlobals(env) {
    PROXY_URL = env.PROXY_URL || PROXY_URL;
}

/**
 * @type {Object}
 */
export const CRON_MAP = {
    '7 * * * *': '/api/offers/refresh',             // PREV: Every hour, starting at 7th minutes past the hour
    '*/15 * * * *': '/api/offers/refresh',          // PROD: Every 15 minutes
    '3/5 * * * *':  '/api/multi/run',               // BOTH: Every 5 minutes, starting at 3rd minutes past the hour
};

/**
 * Seed used in Authorization header
 * @type {String}
 */
export const AUTH_SEED = "R2d|&WT2@47xsg{x*D2";

/**
 * @type {String}
 */
export let PROXY_URL = 'https://gcp-fetch-6esiojd67a-uc.a.run.app/fetch';

/**
 * @type {String}
 */
export const PROXY_AUTH = 'Bearer 63fd53754fcc4cd295f70b3d302fd6352caada87';

/**
 * @type {Object}
 */
export const FILENAME = {
    HEADERS: 'headers.json',
    GAMES: 'games.json',
    CURRENT_GAMES: 'current-games.json',
    CURRENT_OFFERS: 'current-offers.txt',
    PREFIX: {
        LOG: 'log',
        LOG_ARCHIVE: 'log-archive'
    }
};

/**
 * Max number of games to get offers for
 * @type {Number}
 */
export const GAMES_MAX = Infinity;

/**
 * TTL for using cached game data
 * @type {Number}
 */
export const GAMES_TTL = 604800; //1 week

/**
 * Max offer price per ticket; otherwise do not show to user
 * @type {Number}
 */
export const PRICE_MAX = 2000;

/**
 * Do not store offers with these disclosures
 * @deprecated
 * @type {Array}
 */
// export const DISCLOSURE_BLOCKLIST = [
//     MAPS.DISCLOSURES.NO_TICKET.CODE,
//     MAPS.DISCLOSURES.PARK_ONLY.CODE,
//     MAPS.DISCLOSURES.WHEELCHAIR.CODE
// ];

/**
 * @see https://scrapeops.io/app/headers
 * @type {String}
 */
export const SCRAPEOPS_API_KEY = '1b148eea-5d3d-4c40-ac45-0bcd8c0365ee';

/**
 * @type {Number}
 */
export const FETCH_TIMEOUT = 15000; //15s

/**
 * @type {Number}
 */
export const PROJECT_EPOCH = 1694750400000;

/**
 * Hardcoded source data values
 * @type {Object}
 */
export const HARDCODE = {
    NHL: {
        DEFAULT_LOGO: 'https://assets.nhle.com/logos/nhl/svg/NHL_light.svg'
    },
    TICKPICK: {
        PERFORMER_ID: {
            PRESEASON: '71153',
            EASTERN_CONFERENCE_FIRST_ROUND: '147350',
            EASTERN_CONFERENCE_SECOND_ROUND: '147351',
            CONFERENCE_FINALS: '32005',
            PLAYOFFS: '32013'
        }
    }
}

/**
 * Fetch API options
 * @type {Object}
 */
export const FETCH_OPTS = {
    TICKPICK: {
        keepalive: true,
        headers: {
            'accept': '*/*',
            'accept-language': 'en-US,en;q=0.9',
            'accept-encoding': 'gzip',
            'cache-control': 'max-age=0',
            'referer': 'https://www.tickpick.com/',
            'dnt': '1',
            'upgrade-insecure-requests': '1'
        }
    },
    NHLE: {
        keepalive: true,
        headers: {
            'accept': '*/*',
            'accept-language': 'en-US,en;q=0.9',
            'accept-encoding': 'gzip',
            'cache-control': 'max-age=0',
            'referer': 'https://www.nhl.com/'
        }
    },
    PROXY: {
        method: 'POST',
        keepalive: true,
        headers: {
            'accept': '*/*',
            'accept-language': 'en-US,en;q=0.9',
            'accept-encoding': 'gzip',
            'Authorization': PROXY_AUTH,
            'cache-control': 'max-age=0'
        }
    }
};

/**
 * Default headers to use for Response
 * @see https://developers.cloudflare.com/workers/examples/security-headers/
 * @type {Object}
 */
export const RESPONSE_HEADERS = {
    "Content-Security-Policy" : "upgrade-insecure-requests",
    "Strict-Transport-Security" : "max-age=2592000",
    "X-Xss-Protection" : "1; mode=block",
    "X-Frame-Options" : "SAMEORIGIN",
    "X-Content-Type-Options" : "nosniff",
    "Referrer-Policy" : "same-origin", //"strict-origin-when-cross-origin",
    "Cross-Origin-Embedder-Policy": 'require-corp; report-to="default";',
    "Cross-Origin-Opener-Policy": 'same-site; report-to="default";',
    "Cross-Origin-Resource-Policy": "same-site",
    "Permissions-Policy": "interest-cohort=()",
};

/**
 * @see https://developers.google.com/identity/protocols/oauth2/web-server
 * @type {Object}
 */
export const GOOGLE_API = {
    OAUTH_CLIENT_ID: '308790939271-d6h0fpug1huiij2li8dk7ajjf5akjnlg.apps.googleusercontent.com',
    OAUTH_CLIENT_SECRET: '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',
    SCOPES: 'https://www.googleapis.com/auth/drive.appdata',
    DISCOVERY_DOC: 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
    API_KEY: 'AIzaSyBCwxrDea_dukG7oqcxzm6ocdcGl4LmWI4',
    COOKIE: {
        REFRESH_TOKEN: 'gapi_refresh_token',
        ACCESS_TOKEN: 'gapi_access_token',
        EXPIRATION: 'gapi_expiration',
    },
};

export const UI = {
    URL: {
        PARAM_SEPARATOR: {
            DEFAULT: '-',
            QTY: '~'
        }
    },
    OFFERS: {
        ROWS_MAX: 200,
        QTY_MAX: 20
    },
    FILTER: {
        QTY_MAX: 15,
        DEFAULTS: {
            access: ['Game'],
            price: ['1000'],
            qty: ['2~15']
        }
    }
};
