/*
* Popup Window
* @link https://stackoverflow.com/a/16861050
*/
export default function popupWinInCenter(url, title, w, h) {
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, title, `width=${w/systemZoom},height=${h/systemZoom},top=${top},left=${left},noopener=1,noreferrer=1,popup=1,scrollbars=no,resizable=yes,menubar=no,toolbar=no,status=no,fullscreen=no`)

    if(newWindow && window.focus) newWindow.focus();
}