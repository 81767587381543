import * as CONFIG from '../../configs.js';
import * as HELPER from '../../helpers.js';
import { getOAuthRedirectUrl } from '../functions/oauth/google/[action].js';

export default class GoogleApiTokenStorage {
    #access_token;
    #exp_buffer = 30 * HELPER.SEC_IN_MS;

    static async build() {
        const obj = new this(true);
        let access_token = HELPER.getCookieValue(CONFIG.GOOGLE_API.COOKIE.ACCESS_TOKEN, document.cookie);
        let expiration = HELPER.getCookieValue(CONFIG.GOOGLE_API.COOKIE.EXPIRATION, document.cookie);

        if(expiration>0 && (expiration - obj.#exp_buffer) < Date.now()) {
            await obj.#refreshToken();
        } else if(expiration>0 && !access_token) {
            await obj.#refreshToken();
        } else if(access_token) {
            obj.#initData(access_token, expiration);
        }
        
        return obj;
    }

    constructor(is_builder) {
        if(!is_builder) {
            throw new Error('Cannot be called directly; use build()');
        }
    }

    get access_token() {
        return this.#access_token;
    }

    startUserAuth() {
        window.location.href = getOAuthRedirectUrl();
    }

    async revoke() {
        const resp = await fetch('/oauth/google/revoke');
        await resp.text() // @see https://stackoverflow.com/a/67276787
    }

    #initData(access_token, expiration) {
        this.#access_token = access_token;
        if(expiration > 0) {
            setTimeout(
                async ()=>{ this.#refreshToken },
                expiration - Date.now() - this.#exp_buffer
            );
        }
    }

    async #refreshToken() {
        await fetch('/oauth/google/refresh');

        let access_token = HELPER.getCookieValue(CONFIG.GOOGLE_API.COOKIE.ACCESS_TOKEN, document.cookie);
        let expiration = HELPER.getCookieValue(CONFIG.GOOGLE_API.COOKIE.EXPIRATION, document.cookie);

        if(access_token && expiration > Date.now()) {
            this.#initData(access_token, expiration);
        }
    }
}