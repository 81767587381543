import * as CONFIG from '../../configs.js';
import GamesMap from './map.js';
import GameSource from './source.js';

export default class GamesR2 {

    /**
     * Get list of games
     * 
     * @param {Object} env
     * @param {String} format (options: stream, arrayBuffer, text, json)
     * @returns {ReadableStream|Promise<ArrayBuffer>|Promise<String>|Promise<T>|null}
     */
    static async get(env, format='json') {
        const get = await env.BUCKET.get(CONFIG.FILENAME.GAMES);
        if(!get) return null;

        // ordered from fastest to slowest
        switch(format) {
            case 'stream':
                return get.body;
            case 'arrayBuffer':
                return get.arrayBuffer();
            case 'text':
                return get.text();
            case 'json':
            default:
                return get.json();
        }
    }

    /**
     * Save new data
     * 
     * @param {*} env 
     * @param {GamesMap} games 
     * @param {Number} ver 
     * @param {Number} ts 
     * @param {String} hash 
     * @returns {Promise}
     */
    static async save(env, data, ver, ts, hash) {
        return env.BUCKET.put(CONFIG.FILENAME.GAMES, data, {
            customMetadata: { ver, ts, hash },
            httpMetadata: { contentType:'application/json' }
        });
    }

    /**
     * Delete game data
     * 
     * @param {Object} env
     * @returns {Promise}
     */
    static async delete(env) {
        return env.BUCKET.delete(CONFIG.FILENAME.GAMES);
    }

}