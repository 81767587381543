/**
 * JSON-safe ASCII characters (see toBase92 & fromBase92)
 * @var {String}
 */
const ASCII_CHARS_JSON_SAFE = "0123456789!#$%&'()*+,-./:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[]^_`abcdefghijklmnopqrstuvwxyz{|}~";

/**
 * Determine if variable is a string
 * @param {*} str 
 * @returns {Boolean}
 */
export function isString(str) {
	return (typeof str === 'string' || str instanceof String);
}

/**
 * From int base-92 string
 * @param {Number} int 
 * @returns {String}
 */
export function toBase92(int) {
    if (int === 0) {
      return '0';
    }
    const length = ASCII_CHARS_JSON_SAFE.length;
    let result = '';
    while (int > 0) {
        result = ASCII_CHARS_JSON_SAFE[int % length] + result;
        int = parseInt(int / length, 10);
    }
    return result;
}

/**
 * From base-92 string to int
 * @param {String} str 
 * @returns {Number}
 */
export function fromBase92(str) {
    const length = ASCII_CHARS_JSON_SAFE.length;
    let result = 0;
    for (let i=0; i<str.length; i++) {
        let p = ASCII_CHARS_JSON_SAFE.indexOf(str[i]);
        if (p < 0) return NaN;
        result += p * Math.pow(length, str.length - i - 1);
    }
    return result;
}

/**
 * @see https://github.com/SheetJS/js-adler32/blob/master/adler32.js
 * @param {String} str 
 * @param {Number|undefined} seed 
 * @returns {String}
 */
export function adler32(str, seed) {
	var a = 1, b = 0, L = str.length, M = 0, c = 0, d = 0;
	if(typeof seed === 'number') { a = seed & 0xFFFF; b = seed >>> 16; }
	for(var i = 0; i < L;) {
		M = Math.min(L-i, 2918);
		while(M>0) {
			c = str.charCodeAt(i++);
			if(c < 0x80) { a += c; }
			else if(c < 0x800) {
				a += 192|((c>>6)&31);             b += a; --M;
				a += 128|(c&63);
			} else if(c >= 0xD800 && c < 0xE000) {
				c = (c&1023)+64; d = str.charCodeAt(i++) & 1023;
				a += 240|((c>>8)&7);              b += a; --M;
				a += 128|((c>>2)&63);             b += a; --M;
				a += 128|((d>>6)&15)|((c&3)<<4);  b += a; --M;
				a += 128|(d&63);
			} else {
				a += 224|((c>>12)&15);            b += a; --M;
				a += 128|((c>>6)&63);             b += a; --M;
				a += 128|(c&63);
			}
			b += a; --M;
		}
		a = (15*(a>>>16)+(a&65535));
		b = (15*(b>>>16)+(b&65535));
	}
	return ((((b%65521) << 16) | (a%65521)) >>> 0).toString(36)
}
