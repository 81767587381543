import * as CONFIG from '../configs.js';
import * as HELPER from '../helpers.js';
import GamesMap, { IDX as IDXG } from '../models/games/map.js';
import OffersMap from '../models/offers/map.js';
import OffersR2 from '../models/offers/r2.js';
import OffersKv from '../models/offers/kv.js';
import OffersSource from '../models/offers/source.js';
import GamesController from './games.js';
import HeadersController from './headers.js';
import PLimit from 'p-limit';
import { error } from 'itty-router';

export default class OffersController {

    static getRoundedTimestamp(ts=Date.now()) {
        return new Date(Math.floor(ts / HELPER.MIN_IN_MS) * HELPER.MIN_IN_MS).getTime();
    }

    /**
     * @param {*} env 
     * @returns {String}
     */
    static async getCurrentTimestamp(env) {
        return await OffersKv.getTimestamp(env);
    }

    /**
     * Get list
     * 
     * @param {*} env 
     * @param {String} format (options: map, stream, arrayBuffer, text, json)
     * @param {String} source (options: kv, r2)
     * @param {Boolean} refreshIfMissing
     * @returns {OffersMap|ReadableStream|Promise<ArrayBuffer>|Promise<String>|Promise<T>|null}
     */
    static async getData(env, format=undefined, source=undefined, refreshIfMissing=true) {
        format ??= 'map';
        let getFormat = (format==='map') ? 'json' : format;

        const _getData = async (env, getFormat) => {
            switch(source) {
                case 'r2':
                    return await OffersR2.get(env, getFormat);
                case 'kv':
                default:
                    return await OffersKv.get(env, getFormat);
            }
        };

        let data = await _getData(env, getFormat);
        if(!data && refreshIfMissing) {
            await this.refreshData(env);
            data = await _getData(env, getFormat);
        }
        
        return format==='map' ? new OffersMap(HELPER.getObjKey(data, 'offers', undefined)) : data;
    }

    /**
     * @param {*} env 
     * @returns {Object}
     */
    static async refreshData(env) {
        const gamesMap = await GamesController.getData(env);
        const now = Date.now();
        const gameCount = Math.min(CONFIG.GAMES_MAX, gamesMap.size); //limit for debugging
        let offersByGame;
        let stats = {};

        if(env) {
            const gamesInRequestOrder = [];
            const requests = [];

            for (const [key, game] of gamesMap) {
                //don't refresh games more than an hour into start time
                if(game[IDXG.start]+HELPER.HOUR_IN_MS > now) {
                    gamesInRequestOrder.push(game);
                    requests.push({
                        url: 'https://api.tickpick.com/1.0/listings/internal/event/' + game[IDXG.tickpick_id] + '?' + new URLSearchParams({ lid:game[IDXG.tickpick_id] })
                    });
                }
            }
            let gcpFetchOpts = JSON.parse(JSON.stringify(CONFIG.FETCH_OPTS.PROXY));
            gcpFetchOpts.body = JSON.stringify(requests);
            const start = performance.now();
            let data = await HELPER.fetchRespJSON(CONFIG.PROXY_URL, gcpFetchOpts, 'Proxy: all games', 2, 10);
            const respTime = (performance.now() - start) / 1000;

            offersByGame = (Array.isArray(data) ? data : data.result || [])
                .map(offers => {
                    const gameInRequestOrder = gamesInRequestOrder.shift();
                    if(offers.length) {
                        return OffersSource.parseOffersForGame(gameInRequestOrder, offers);
                    }
                    return [];
                });

            stats = {
                requested: requests.length,
                responses: offersByGame.length,
                responsesWithOffers: offersByGame.filter(offers => offers.length).length,
                totalOffers: offersByGame.reduce((total,offer) => total+offer.length, 0),
                avgOfferPerGame: offersByGame.length ? (offersByGame.reduce((total,offer) => total+offer.length, 0) / offersByGame.length) : 0,
                respTime
            };

            if(!HELPER.IS_LOCAL) {
                env.WAE_FETCH_OFFERS.writeDataPoint({
                    'blobs': [],
                    'doubles': Object.values(stats),
                    'indexes': ['offers']
                });
            }

            stats.D1 = await env.D1.prepare('INSERT INTO "log_fetch_offers" (req_cnt, resp_cnt, offer_cnt, resp_time) VALUES (?1, ?2, ?3, ?4)')
                .bind(stats.requested, stats.responsesWithOffers, stats.totalOffers, respTime)
                .run();
            
        } else {
            // Was seeing "Response closed due to connection limit" exceptions sometimes being thrown
            // @see https://community.cloudflare.com/t/cf-worker-response-closed-due-to-connection-limit/198119/5
            // @see https://developers.cloudflare.com/workers/platform/limits/#simultaneous-open-connections
            const limit = PLimit((HELPER.IS_LOCAL || !env) ? Infinity : 6);
            const promises = [];
            for (const [key, game] of gamesMap) {
                //don't refresh games more than an hour into start time
                if(game[IDXG.start]+HELPER.HOUR_IN_MS > now) {
                    promises.push(limit(() => OffersSource.getOffersForGame(env, game)));
                    if(promises.length >= gameCount) break;
                }
            }
            offersByGame = (await Promise.allSettled(promises))
                .map(result => result.value || [])
                .filter(offers => offers.length);
        }

        const offersMap = new OffersMap();
        offersByGame.forEach(offers => {
            offers.forEach(offer => offersMap.add(offer));
        });

        if(offersMap.size > 0) {
            const ver = 1;
            const ts = Date.now();
            const gamesMapSize = gamesMap.size;
            const offersMapSize = offersMap.size;

            if(env) {
                const data = JSON.stringify({
                    ver,
                    ts,
                    cnt: { games:gamesMapSize, offers:offersMapSize },
                    games: gamesMap,
                    offers: offersMap
                });

                const writes = (await Promise.allSettled([
                    OffersR2.save(env, data, ver, ts),
                    OffersKv.save(env, data, ver, ts)
                ])).map(result => result.value || []);

                return { games:gamesMapSize, offers:offersMapSize, stats, writes };
            }

            return {
                ver,
                ts,
                cnt: { games:gamesMapSize, offers:offersMapSize },
                games: Array.from(gamesMap.values()),
                offers: Array.from(offersMap.values()),
                stats
            }
        }
        return error(500, {
            error:'No offers found',
            stats
        });
    }

    static async buildRollup(env, maxFilesToProcess=undefined, showResults=false) {
        return await OffersR2Rollup.build(env, maxFilesToProcess, showResults);
    }

    static async viewRollup(env, gameId) {
        return await OffersR2Rollup.view(env, gameId);
    }

    static async deleteRollup(env, gameId) {
        return await OffersR2Rollup.delete(env, gameId);
    }

    /**
     * @param {*} env 
     * @returns {Promise}
     */
    static async deleteData(env) {
        return (await Promise.allSettled([
            await OffersR2.delete(env),
            await OffersKv.delete(env)
        ])).map(result => result.value || []);
    }

}