import * as CONFIG from '../../configs.js';

export default class GamesKv {

    /**
     * Get list of offers
     * 
     * @param {*} env 
     * @param {String} format (aka type; options: stream, arrayBuffer, text, json)
     * @returns {ReadableStream|Promise<ArrayBuffer>|Promise<String>|Promise<T>|null}
     */
    static get(env, format='json') {
        // ordered from fastest to slowest
        // @see https://developers.cloudflare.com/kv/api/read-key-value-pairs/#types
        const validOpts = ['stream', 'arrayBuffer', 'text', 'json'];
        return env.KV.get(CONFIG.FILENAME.CURRENT_GAMES, {
            type: validOpts.includes(format) ? format : 'json',
            cacheTTL: 60
        });
    }

    /**
     * Get 'hash' value of current file
     * @param {*} env 
     * @returns {String|null}
     */
    static async getHash(env) {
        const { value, metadata } = await env.KV.getWithMetadata(CONFIG.FILENAME.CURRENT_GAMES, { type:'stream', cacheTTL:60 }); //'stream' so data isn't returned since it's not used
        if(metadata && metadata.hash) {
            return metadata.hash;
        }
        return null;
    }

    /**
     * Save new data
     * 
     * @param {*} env 
     * @param {String} data 
     * @param {Number} ver 
     * @param {Number} ts 
     * @param {String} hash 
     * @returns {Promise}
     */
    static async save(env, data, ver, ts, hash) {
        return env.KV.put(CONFIG.FILENAME.CURRENT_GAMES, data, {
            metadata: { ver, ts, hash, contentType:'application/json' },
        });
    }

    /**
     * Delete offer data
     * 
     * @param {Object} env
     * @returns {Promise}
     */
    static async delete(env) {
        return env.KV.delete(CONFIG.FILENAME.CURRENT_GAMES);
    }

}