import * as HELPER from '../helpers.js';
import { html } from 'itty-router';
import template from "../cf-workers/templates/danger.js";

/**
 * 
 * @param {String|NodeList} elems 
 * @param {Object} attrs
 */
export function setAttributes(elems, attrs) {
    if(HELPER.isString(elems)) {
        elems = document.querySelectorAll(elems) || [];
    }
    elems.forEach(elem => {
        for(var key in attrs) {
            elem.setAttribute(key, attrs[key]);
        }
    });
};

/**
 * Sanitize string for HTML
 * 
 * @param {*} str 
 * @returns {String}
 */
export function sanitize(str) {
    return (new DOMParser()).parseFromString(str, "text/html").documentElement.textContent;
}

/**
 * Escape HTML special characters
 * 
 * @param {String} unsafe 
 * @returns {String}
 */
export function escapeHtml(unsafe) {
    return unsafe
        .replaceAll('&', '&amp;')
        .replaceAll('<', '&lt;')
        .replaceAll('>', '&gt;')
        .replaceAll('"', '&quot;')
        .replaceAll("'", '&#039;');
}

export function dangerResponse(request, env, ctx, callbackFn=false) {
    var url = new URL(request.url);
    const seed = Math.floor(Date.now() / HELPER.MIN_IN_MS);
    const validTokens = [HELPER.adler32(url.pathname, seed), HELPER.adler32(url.pathname, seed-1)];
    
    if(!validTokens.includes(request.query.danger)) {
        url.searchParams.set('danger', validTokens[0]);
        const uri = url.toString().replace(url.origin, '');
        return html(template(uri, HELPER.MIN_IN_MS));
    }
    if(callbackFn) {
        return callbackFn();
    }
};

/**
 * Get cookie value from document.cookie string
 * 
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie#example_2_get_a_sample_cookie_named_test2
 * @param {String} name 
 * @param {String} cookieJar 
 * @returns {String||undefined}
 */
export function getCookieValue(name, cookieJar) {
    return cookieJar
        .split("; ")
        .find((row) => row.startsWith(`${name}=`))
        ?.split("=")[1];
}

/**
 * Replacement for Element.innerHTML = '...'
 * 
 * @see https://blog.stevenlevithan.com/archives/faster-than-innerhtml
 * @param {*} origElem 
 * @param {*} html 
 * @returns 
 */
export function replaceHtml(origElem, html) {
	origElem = (typeof origElem === "string") ? document.getElementById(origElem) : origElem;
	const newElem = origElem.cloneNode(false);
	newElem.innerHTML = html;
	origElem.parentNode.replaceChild(newElem, origElem);
	return newElem;
};