import * as CONFIG from '../../../../configs.js';
import * as HELPER from '../../../../helpers.js';

/**
 * GOOGLE OAUTH 2.0
 * 
 * Initial authentication path:
 *   1. Set authorization parameters
 *         This is done in getOAuthRedirectUrl()
 *   2. Redirect to Google's OAuth 2.0 server
 *         Import getOAuthRedirectUrl() to frontend code and send user to URL returns by that function
 *   3. Google prompts user for consent
 *         Nothing to do here -- happens on Google's end
 *   4. Handle the OAuth 2.0 server response
 *         Google redirects user back to /oauth/google/callback which calls callback()
 *   5. Exchange authorization code for refresh and access tokens
 *         This is done in callback()
 * 
 * Refreshing an access token:
 *   1. Call /oauth/google/refresh via ajax to get new access token
 * 
 * Revoking a token:
 *   1. Call /oauth/google/revoke via ajax to log user out
 * 
 * @see https://developers.google.com/identity/protocols/oauth2/web-server
 */

/**
 * Router
 * 
 * Actions are whitelisted in src/cf-pages/_routes.json
 * 
 * @param {*} context { request, env, params }
 * @returns Response
 */
export async function onRequestGet({ request, env, params }) {
    HELPER.initGlobals(env);

    switch (params.action) {
        case 'callback':
            return await callback(request);
        case 'refresh':
            return await refresh(request);
        case 'revoke':
            return await revoke(request);
        default:
            return new Response('Not found', { status:404 });
    }
}

/**
 * To be imported by frontend code
 * 
 * @returns String
 */
export function getOAuthRedirectUrl(url=document.location.href) {
    const endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
    const redirectUrl = (new URL(url)).origin + '/oauth/google/callback';
    const params = {
        'client_id': CONFIG.GOOGLE_API.OAUTH_CLIENT_ID,
        'redirect_uri': redirectUrl,
        'response_type': 'code',
        'access_type': 'offline',
        'scope': CONFIG.GOOGLE_API.SCOPES,
        'state': redirectUrl,
        'prompt': 'consent'
    };
    return endpoint + '?' + new URLSearchParams(params);
}

/**
 * Initial authentication path > Handle the OAuth 2.0 server response
 * 
 * @param {*} request 
 * @returns Response
 */
async function callback(request) {
    const url = new URL(request.url);
    const params = url.searchParams;
    const code = params.get('code');
    const state = params.get('state');
    const error = params.get('error') || 'Unknown error';

    if(!code) {
        return new Response(error, { status:500 });
    }
    
    const endpoint = 'https://oauth2.googleapis.com/token';
    const json = await HELPER.fetchRespJSON(endpoint, {
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'client_id': CONFIG.GOOGLE_API.OAUTH_CLIENT_ID,
            'client_secret': CONFIG.GOOGLE_API.OAUTH_CLIENT_SECRET,
            'code': code,
            'grant_type': 'authorization_code',
            'redirect_uri': state
        })
    });

    const headers = _initHeaders(url, json.refresh_token, json.access_token, json.expires_in);
    headers.set('Location', '/');
    return new Response(null, { headers, status:302, statusText:'Found' });
}

/**
 * Refreshing an access token
 * 
 * @param {*} request 
 * @returns String
 */
async function refresh(request) {
    const refreshToken = HELPER.getCookieValue(CONFIG.GOOGLE_API.COOKIE.REFRESH_TOKEN, request.headers.get('Cookie'));

    const endpoint = 'https://oauth2.googleapis.com/token';
    const json = await HELPER.fetchRespJSON(endpoint, {
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'client_id': CONFIG.GOOGLE_API.OAUTH_CLIENT_ID,
            'client_secret': CONFIG.GOOGLE_API.OAUTH_CLIENT_SECRET,
            'refresh_token': refreshToken,
            'grant_type': 'refresh_token'
        })
    });

    const headers = _initHeaders(new URL(request.url), refreshToken, json.access_token, json.expires_in);
    
    if(json.access_token) {
        return new Response(null, { headers, status:204, statusText:'No Content' });
    } else {
        const body = json.error || 'Unknown error response';
        return new Response(body, { headers, status:400, statusText:'Bad Request' });
    }
}

/**
 * Revoking a token
 * 
 * @param {*} request 
 * @returns String
 */
async function revoke(request) {
    const token = HELPER.getCookieValue(CONFIG.GOOGLE_API.COOKIE.REFRESH_TOKEN, request.headers.get('Cookie'));
    
    if(token) {
        const endpoint = 'https://oauth2.googleapis.com/revoke';
        HELPER.fetchRespJSON(endpoint, {
            method:'POST',
            headers: { 'Content-Type':'application/json' },
            body: JSON.stringify({ token })
        });
    }

    const headers = _initHeaders(new URL(request.url));
    return new Response(null, { headers, status:204, statusText:'No Content' });
}

/**
 * Set initial headers, including cookies, and return the Headers object
 * 
 * @param {URL} url 
 * @param {String} refresh_token 
 * @param {String} access_token 
 * @param {Number} expires_in 
 * @returns {Headers}
 */
function _initHeaders(url, refresh_token='', access_token='', expires_in=0) {
    const headers = new Headers();
    headers.set('Cache-Control', 'no-store');
    headers.set('Expires', '0');

    if(!refresh_token || !access_token || !expires_in || expires_in<0) {
        refresh_token = '';
        access_token = '';
        expires_in = 0;
    }

    const cookieAttrs = `Domain=${url.hostname}; SameSite=Lax ${(url.protocol === 'https:') ? '; isSecure' : ''}`;
    headers.append('Set-Cookie', `${CONFIG.GOOGLE_API.COOKIE.REFRESH_TOKEN}=${refresh_token}; Max-Age=${HELPER.YEAR_IN_SEC}; Path=/oauth/google/; HttpOnly; ${cookieAttrs}`); // note: Max-Age, Path, & HttpOnly
    headers.append('Set-Cookie', `${CONFIG.GOOGLE_API.COOKIE.EXPIRATION}=${Date.now()*expires_in}; Max-Age=${HELPER.YEAR_IN_SEC}; Path=/; ${cookieAttrs}`); // note: Max-Age
    headers.append('Set-Cookie', `${CONFIG.GOOGLE_API.COOKIE.ACCESS_TOKEN}=${access_token}; Max-Age=${expires_in}; Path=/; ${cookieAttrs}`);

    return headers;
}