/**
 * 
 */
export default function initGlobalAddons() {
    _initDOMContentChanged();

    if(document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', onDOMContentLoaded);
    } else {
        setTimeout(onDOMContentLoaded, 1);
    }
    
    document.addEventListener('DOMContentChanged', onDOMContentChanged);
}

/**
 * Call DOMContentChanged whenever part of the DOM is changed
 */
function _initDOMContentChanged() {
    let timerId;
    const fireEvent = () => document.dispatchEvent(new Event('DOMContentChanged'));
    const fireEventDelayed = () => {
        clearTimeout(timerId);
        timerId = setTimeout(fireEvent, 500);
    }
    const mutationObserver = new MutationObserver((mutations) => mutations.forEach(fireEventDelayed));
    mutationObserver.observe(document.documentElement, {
        attributes: false,
        characterData: true,
        childList: true,
        subtree: true,
        attributeOldValue: false,
        characterDataOldValue: false
    });
    document.addEventListener('DOMContentLoaded', fireEventDelayed);
}

/**
 * Shortcut for ([document|elem].querySelectorAll(selector) || []).forEach(el => callbackFn);
 * 
 * Function overloading/Polymorphism:
 *  - forEachElement(selector, callbackFn)
 *  - forEachElement(root, selector, callbackFn)
 * 
 * If 'root' is not passed, then 'root' is set to 'document'
 * 
 * @overload
 * @param {Document|DocumentFragment|Element} root
 * @param {String} selector
 * @param {Function} callbackFn
 * 
 * @overload
 * @param {String} selector
 * @param {Function} callbackFn
 */
function forEachElement(...args) {
    const [root, selector, callbackFn] = (args.length===2) ? [document, ...args] : args;
    (root.querySelectorAll(selector) || []).forEach(callbackFn);
}

/**
 * onDOMContentLoaded
 */
function onDOMContentLoaded() {

    /**
     * NAVBAR
     */
    forEachElement('.navbar-burger', (el) => {
        el.addEventListener('click', () => {
            el.classList.toggle('is-active');
            forEachElement(el.closest('.navbar'), '.navbar-menu', (mEl)=>mEl.classList.toggle('is-active'));
        });
    });
    
    /**
     * NAVBAR DROPDOWN
     */
    document.querySelector('body').addEventListener('click', (event) => {
        const clickedNavItemEl = event.target.closest('.navbar-item.dropdown');
        const clickedMenuEl = event.target.closest('.dropdown-menu');

        forEachElement('.navbar-item.dropdown.is-active', (el) => {
            if(clickedNavItemEl !== el) el.classList.remove('is-active');
        });

        if(clickedNavItemEl && !clickedMenuEl) {
            clickedNavItemEl.classList.toggle('is-active');
        }
    });

}

/**
 * onDOMContentChanged
 */
function onDOMContentChanged() {

    /**
     * MODAL
     */
    (() => {
        const openModal = (el) => el.classList.add('is-active');
        const closeModal = (el) => el.classList.remove('is-active');
        const closeAllModals = () => forEachElement('.modal', closeModal);
      
        forEachElement('.js-modal-trigger:not([data-js-modal-attached])', (el) => {
            el.setAttribute('data-js-modal-attached', '');
            const target = document.getElementById(el.dataset.target);
            el.addEventListener('click', (event) => {
                if(el.dataset.targetlink) {
                    forEachElement(target, 'a.modal-target-link', (aEl) => aEl.setAttribute('href', el.dataset.targetlink));
                }
                if(el.dataset.copy) {
                    forEachElement(target, '.toggle-copy', (copyEl) => copyEl.innerHTML=el.dataset.copy);
                }
                openModal(target);
            });
        });
      
        forEachElement('.modal-background, .modal-close, .modal-close-btn, .modal-card-head .delete, .modal-card-foot .button', (el) => {
            el.addEventListener('click', ()=>closeModal(el.closest('.modal')));
        });
      
        document.addEventListener('keydown', (event) => {
            if(event.code === 'Escape') closeAllModals();
        });
    })();

    /**
     * VIBRATE ON ACTIONS
     */
    (() => {
        if('vibrate' in navigator) {
            forEachElement('a:not([data-haptic-attached]), button:not([data-haptic-attached])', (el) => {
                el.setAttribute('data-haptic-attached', '');
                el.addEventListener('click', () => window.navigator.vibrate(1));
            });
            forEachElement('input:not([data-haptic-attached])', (el) => {
                el.setAttribute('data-haptic-attached', '');
                el.addEventListener('change', () => window.navigator.vibrate(1));
            });
        }
    })();

    /**
     * TOGGLE SHOW/HIDDEN
     */
    forEachElement('.js-toggle[data-target]:not([data-toggle-attached])', (el) => {
        el.setAttribute('data-toggle-attached', '');
        el.addEventListener('click', () => {
            const ids = el.dataset.target.split(',') || [];
            ids.forEach(id => document.getElementById(id).classList.toggle('is-hidden'));
        });
    });

    /**
     * SCROLL TO TOP ON CLICK
     */
    forEachElement('.js-scroll-to-top:not([data-scroll-to-top-attached])', (el) => {
        el.setAttribute('data-scroll-to-top-attached', '');
        el.addEventListener('click', () => {
            window.scrollTo({ top:0, left:0, behavior:'smooth' });
        });
    });

    /**
     * CHECKBOX BUTTONS
     */
    forEachElement('.buttons input[type="checkbox"]:not([data-checkbox-button-attached])', (el) => {
        el.setAttribute('data-checkbox-button-attached', '');
        const labelElem = el.closest('label');
        const toggleClassList = ['is-selected', 'is-success'];
        const binder = () => el.checked ? labelElem.classList.add(...toggleClassList) : labelElem.classList.remove(...toggleClassList);
        el.addEventListener('change', binder);
        binder();
    });

    /**
     * TOGGLE ALL / NONE
     */
    forEachElement('.toggle-all-none .toggle-all:not([data-toggle-all-none-attached])', (el) => {
        el.setAttribute('data-toggle-all-none-attached', '');
        el.addEventListener('click', () => {
            forEachElement(el.closest('.content'), 'input[type="checkbox"]', (cboxEl) => {
                cboxEl.checked = true;
                cboxEl.dispatchEvent(new Event('change'));
            });
        });
    });
    forEachElement('.toggle-all-none .toggle-none:not([data-toggle-all-none-attached])', (el) => {
        el.setAttribute('data-toggle-all-none-attached', '');
        el.addEventListener('click', () => {
            forEachElement(el.closest('.content'), 'input[type="checkbox"]', (cboxEl) => {
                cboxEl.checked = false;
                cboxEl.dispatchEvent(new Event('change'));
            });
        });
    });

}
