import HeadersR2 from '../models/headers/r2.js';
import HeadersSource from '../models/headers/source.js';

export default class HeadersController {

    /**
     * @var {Object}
     */
    static _random;
    
    /**
     * Get a random set of HTTP headers
     * 
     * @param {*} env 
     * @param {Object} overwrites
     * @param {Boolean} refreshIfMissing
     * @returns {Object}
     */
    static async getRandom(env, overwrites={}, refreshIfMissing=true) {
        if(!this._random) {
            const headers = await this.getData(env, null, refreshIfMissing);
            if(headers && headers.length) {
                this._random = headers[Math.floor(Math.random() * headers.length)];
            }
        }
        return { ...this._random, ...overwrites };
    }

    /**
     * 
     * @param {*} env 
     * @param {String} format (options: array, json)
     * @param {Boolean} refreshIfMissing
     * @returns {Array|Object}
     */
    static async getData(env, format=undefined, refreshIfMissing=true) {
        format = (format==='json') ? 'json' : 'array';

        let data = await HeadersR2.get(env);
        if(!data && refreshIfMissing) {
            await this.refreshData(env);
            data = await HeadersR2.get(env);
        }

        return (data && format==='array') ? data.headers : data;
    }

    /**
     * @param {*} env 
     * @returns {String}
     */
    static async refreshData(env) {
        const data = await HeadersSource.refresh(env);
        await HeadersR2.save(env, data);
        return data.length;
    }

    /**
     * @param {*} env 
     * @returns {null}
     */
    static async deleteData(env) {
        return await HeadersR2.delete(env);
    }

}