import * as HELPER from '../helpers.js';
import GamesMap, { IDX as IDXG } from '../models/games/map.js';
import GamesR2 from '../models/games/r2.js';
import GamesKv from '../models/games/kv.js';
import GamesSource from '../models/games/source.js';

export default class GamesController {

    /**
     * @param {*} env 
     * @returns {String}
     */
    static async getCurrentHash(env) {
        return await GamesKv.getHash(env);
    }

    /**
     * Get list
     * 
     * @param {*} env 
     * @param {String} format (options: map, stream, arrayBuffer, text, json)
     * @param {String} source (options: kv, r2)
     * @param {Boolean} refreshIfMissing
     * @returns {GamesMap|ReadableStream|Promise<ArrayBuffer>|Promise<String>|Promise<T>|null}
     */
    static async getData(env, format=undefined, source=undefined, refreshIfMissing=true) {
        format ??= 'map';
        let getFormat = (format==='map') ? 'json' : format;

        const _getData = async (env, getFormat) => {
            switch(source) {
                case 'r2':
                    return await GamesR2.get(env, getFormat);
                case 'kv':
                default:
                    return await GamesKv.get(env, getFormat);
            }
        };

        let data;
        if(env) {
            data = await _getData(env, getFormat);
            if(!data && refreshIfMissing) {
                await this.refreshData(env);
                data = await _getData(env, getFormat);
            }
        } else {
            return await this.refreshData(env);
        }

        return format==='map' ? new GamesMap(HELPER.getObjKey(data, 'games', undefined)) : data;
    }

    /**
     * @param {*} env 
     * @returns {String}
     */
    static async refreshData(env) {
        const ts = Date.now();
        let gamesMap = new GamesMap();

        if(env) {
            const data = await this.getData(env, 'json', 'r2', false);
            gamesMap = new GamesMap(HELPER.getObjKey(data, 'games', []));
            gamesMap.forEach(game => { game[IDXG.updated_at] ??= data.ts; });
        }

        const newGamesMap = await GamesSource.refresh(env, ts);
        newGamesMap.forEach(newGame => gamesMap.merge(newGame));
        const hash = HELPER.adler32(JSON.stringify(gamesMap));

        if(env) {
            if(gamesMap.size > 0 && this.getCurrentHash() !== hash) {
                const ver = 1;
                const ts = Date.now();

                // remove games in the past
                const kvGames = gamesMap.toJSON()
                    .filter(game => game[IDXG.start] > ts);

                const r2Data = JSON.stringify({ ver, ts, hash, cnt:gamesMap.size, games:gamesMap });
                const kvData = JSON.stringify({ ver, ts, hash, cnt:kvGames.length, games:kvGames });

                const writes = (await Promise.allSettled([
                    GamesR2.save(env, r2Data, ver, ts, hash),
                    GamesKv.save(env, kvData, ver, ts, hash)
                ])).map(result => result.value || []);
            }
        }

        return env ? gamesMap.size : gamesMap;
        
    }

    /**
     * @param {*} env 
     * @returns {null}
     */
    static async deleteData(env) {
        return (await Promise.allSettled([
            await GamesR2.delete(env),
            await GamesKv.delete(env)
        ])).map(result => result.value || []);
    }

}