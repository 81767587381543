import * as MAP from '../../maps.js';
import * as HELPER from '../../helpers.js';
import GamesMap, { IDX as IDXG } from '../games/map.js';

export const IDX = Object.fromEntries([
    'id','game_id','side','level','section','section_name','row','seat_from','seat_to','qty','splits','price','access','created_at','updated_at','is_active','history'
].map((v,k)=>[v,k]));

export default class OffersMap extends Map {

    /**
     * @param {Array} arr
     * @returns {self}
     */
    constructor(arr=[]) {
        super(); //explicitly NOT passing arr
        arr.forEach(row => this.add(row));
    }

    /**
     * @returns {Array}
     */
    toJSON() {
        return Array.from(this.values());
    }

    /**
     * @param {Array} arr
     * @returns {self}
     */
    static fromJSON(arr) {
        return new this(arr);
    }

    /**
     * @param {Object}
     * @returns {self}
     */
    add(value) {
        return this.set(value[IDX.id], value);
    }

    /**
     * Transform entry data to an object
     * 
     * @param {Number} id
     * @returns {Object}
     */
    entryToObject(id) {
        const obj = {};
        const data = this.get(id);
        for(const [k,v] of Object.entries(IDX)) {
            obj[k] = data[v];
        }
        return obj;
    }

    /**
     * Transform object data to format that frontend needs to injest it
     * 
     * @param {Number} id
     * @param {GamesMap} gamesMap 
     * @returns {Object}
     */
    entryToFrontendJSON(id, gamesMap) {
        const obj = this.entryToObject(id);

        if(obj.section_name === MAP.SECTION.UNKNOWN.ID) {
            obj.section_name = HELPER.mapper(MAP.LEVEL, 'ID', 'PREFIX', obj.level) + ' ' + obj.section;
        }

		const game = gamesMap.entryToFrontendJSON(obj.game_id);
        obj.tickpick_game_id = game.tickpick_id;
        obj.type = game.type;
        obj.opponent = game.opponent;
        obj.opponent_abbrev = game.opponent_abbrev;
        obj.opponent_logo = game.opponent_logo;
        obj.start = game.start;
        obj.date = game.date;
        obj.month = game.month;
        obj.time = game.time;
        obj.day = game.day;
        obj.hour = game.hour;

        if(obj.splits === 0) {
            obj.splits = [obj.qty];
        } else {
            obj.splits.sort((x,y) => x-y);
        }

        obj.side = HELPER.mapper(MAP.SIDE, 'ID', 'TEXT', obj.side);
		obj.level = HELPER.mapper(MAP.LEVEL, 'ID', 'TEXT', obj.level);
        obj.access = HELPER.mapper(MAP.ACCESS, 'ID', 'TEXT', obj.access);

        if(!obj.seat_from) obj.seat_from = '';
        if(!obj.seat_to) obj.seat_to = '';

        //add rating
        if(
            (1 <= obj.section && obj.section <= 21 && obj.row == 1) ||                      // Any loge, Row 1
            ([1,2,11,12,13,21].includes(obj.section) && obj.row >= 10) ||                   // Any center loge, row 10+
            ([109,111,113,139,141,143].includes(obj.section)) ||                            // Any center club
            ([509,510,511,512,513,514,539,540,541,542,543,544].includes(obj.section)) ||    // Any center 5xx suite
            ([609,610,611,612,613,614,639,640,641,642,643,644].includes(obj.section)) ||    // Any center 6xx suite
            ([301,302,315,316,317,330].includes(obj.section) && obj.row <= 2)               // Any center balcony, rows 1 - 2
            ) {
            obj.rating = 'Best';
        } else if(
            ([1,2,11,12,13,21].includes(obj.section)) ||                                    // Any center loge
            (100 <= obj.section && obj.section <= 199) ||                                   // Any club
            (500 <= obj.section && obj.section <= 599) ||                                   // Any 5xx suite
            (600 <= obj.section && obj.section <= 699) ||                                   // Any 6xx suite
            (300 <= obj.section && obj.section <= 399 && obj.row == 1) ||                   // Any balcony, row 1
            ([301,302,315,316,317,330].includes(obj.section) && obj.row <= 6) ||            // Any center balcony, rows 3 - 6
            ([701,702,703,704,705,706].includes(obj.section))                               // Any center rafter-level
            ) {
            obj.rating = 'Better';
        } else if(
            (1 <= obj.section && obj.section <= 21) ||                                      // Any loge
            ([301,302,303,314,315,316,317,318,329,330].includes(obj.section)) ||            // Any center-ish balcony
            (700 <= obj.section && obj.section <= 799)                                      // Any rafter-level
            ) {
            obj.rating = 'Good';
        } else {
            obj.rating = 'OK';
        }

        // ## URL TO LISTING PAGE WITH AS MANY FILTERS AS POSSIBLE
        // purposesfully uses encodeURI (instead of encodeURIComponent or URLSearchParams) since that's what TickPick requires
        // obj.link = 'https://www.tickpick.com/buy-tickets/' + encodeURI(game.tickpick_id) + '/'
        //     + '?qty=' + encodeURI(obj.qty + '-false')
        //     + '&price=' + encodeURI(obj.price + ',' + obj.price)
        //     + (obj.section<=330 ? '&sections=' + encodeURI('stars:any|include:' + obj.section + '|exclude:all') : '')
        //     + (obj.section_name===MAP.SECTION.UNKNOWN.TEXT ? '&vip=true' : '')
        //     + '&mine=' + encodeURI(obj.id);

        // ## URL TO CHECKOUT PAGE
        obj.link = 'https://www.tickpick.com/checkout?' + (new URLSearchParams({
            listingType: 'TP',
            listingId: obj.id,
            e: obj.game_id,
            price: obj.price,
            quantity: obj.qty,
        }));

        return obj;
    }
}