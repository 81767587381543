import * as CONFIG from '../../configs.js';
import * as HELPER from '../../helpers.js';

export default class OffersR2 {
    static _logFilename;

    /**
     * Get list
     * 
     * @param {Object} env
     * @param {String} format (options: stream, arrayBuffer, text, json)
     * @returns {ReadableStream|Promise<ArrayBuffer>|Promise<String>|Promise<T>|null}
     */
    static async get(env, format='json') {
        const get = await env.BUCKET.get(this.getCurrentLogFilename());
        if(!get) return null;

        // ordered from fastest to slowest
        switch(format) {
            case 'stream':
                return get.body;
            case 'arrayBuffer':
                return get.arrayBuffer();
            case 'text':
                return get.text();
            case 'json':
            default:
                return get.json();
        }
    }

    /**
     * Save new data
     * 
     * @param {*} env 
     * @param {String} data 
     * @param {Number} ver 
     * @param {Number} ts 
     * @returns {Promise}
     */
    static async save(env, data, ver, ts) {
        return env.BUCKET.put(this.getCurrentLogFilename(), data, { 
            customMetadata: { ver, ts },
            httpMetadata: { contentType:'application/json' }
        });
    }

    /**
     * Delete offer data
     * 
     * @param {Object} env
     * @returns {Promise}
     */
    static async delete(env) {
        return env.BUCKET.delete(this.getCurrentLogFilename());
    }
    
    /**
     * Creates current time period's filename
     * 
     * @returns {String}
     */
    static getCurrentLogFilename() {
        if(!this._logFilename) {
            const date = new Date();
            const ts = date.toISOString().substring(0,10)
                + HELPER.R2_DS + date.getUTCHours().toString().padStart(2,'0')
                + ':' + (Math.floor(date.getUTCMinutes()/15)*15).toString().padStart(2,'0');
            this._logFilename = this._getLogFilenameByDate(new Date())
        }
        return this._logFilename;
    }

    /**
     * 
     * @param {Number} daysAgo 
     */
    static getLogFilenameByDayRange(days) {
        const filenames = [];
        do {
            const date = new Date();
            date.setDate(date.getDate() - days + 1);
            filenames.push(this._getLogFilenameByDate(date));
        } while(--days);
        return filenames;
    }

    /**
     * 
     * @param {Date} date 
     */
    static _getLogFilenameByDate(date) {
        const ts = date.toISOString().substring(0,10)
            + HELPER.R2_DS + date.getUTCHours().toString().padStart(2,'0')
            + ':' + (Math.floor(date.getUTCMinutes()/15)*15).toString().padStart(2,'0');
        return CONFIG.FILENAME.PREFIX.LOG + HELPER.R2_DS + ts + '.json';
    }

    /**
     * Add metadata to R2 log files
     * 
     * @param {*} env 
     * @param {Boolean} showResults 
     * @returns {Object|null}
     */
    static async fixMissingMetaData(env, showResults=false) {
        const files = await HELPER.listR2Objects(
            env.BUCKET,
            CONFIG.FILENAME.PREFIX.LOG + HELPER.R2_DS,
            null,
            { include:['customMetadata'] }
        );

        for(let i=0; i<files.objects.length; i++) {
            const obj = files.objects[i];
            let ver = obj.customMetadata.ver || null;
            let ts = obj.customMetadata.ts || null;
    
            if(!ver || !ts) {
                const getFirstChars = await env.BUCKET.get(obj.key, { range:{ length:100 }});
        
                const firstChars = await getFirstChars.text();
                const ver = HELPER.toNumberIfNumber((new RegExp(/[{,]"ver":(\d+),/).exec(firstChars) || [0,ver])[1]);
                const ts = HELPER.toNumberIfNumber((new RegExp(/[{,]"ts":(\d+),/).exec(firstChars) || [0,ts])[1]);
    
                const get = await env.BUCKET.get(obj.key);
                await env.BUCKET.put(
                    obj.key,
                    await get.arrayBuffer(),
                    {
                        customMetadata: { ver, ts },
                        httpMetadata: { contentType:'application/json' }
                    }
                );  
            }
        }
    
        if(showResults) {
            const files2 = await HELPER.listR2Objects(
                env.BUCKET,
                CONFIG.FILENAME.PREFIX.LOG + HELPER.R2_DS,
                null,
                { include:['httpMetadata','customMetadata'] }
            );
            HELPER.sortR2ListByMetadataTag(files2.objects, 'ts');
            return files2;
        }
    }

}