import * as CONFIG from '../../configs.js';
import * as HELPER from '../../helpers.js';

export default class HeadersSource {

    /**
     * Get list of headers
     * 
     * @param {Object} env
     * @returns {Number}
     */
    static async refresh(env) {
        let url = 'https://headers.scrapeops.io/v1/browser-headers?' + new URLSearchParams({
            api_key: CONFIG.SCRAPEOPS_API_KEY,
            num_results: 10
        });

        let data = await HELPER.fetchRespJSON(url, {}, 'Headers list');

        if(data && data.hasOwnProperty('result')) {
            return data.result;
        }

        return false;
    }

}