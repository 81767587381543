import * as HELPER from '../helpers.js';
import pluralize from 'pluralize';

/**
 * Format number to currency
 * 
 * @param {Number} val 
 * @param {Boolean} hideCents 
 * @returns {String}
 */
export function toCurrency(val, hideCents=true) {
    let opts = { style:'currency', currency:'USD' };
    if(hideCents) opts.maximumFractionDigits = 0;
    return (new Intl.NumberFormat('en-US', opts)).format(val);
}

/**
 * Format number to formatted string
 * 
 * @param {Number} val 
 * @param {Number} fractionDigits (number of decimal places to round to)
 * @returns {String}
 */
export function toDecimal(val, fractionDigits=0) {
    let opts = { style:'decimal', maximumFractionDigits:fractionDigits, minimumFractionDigits:fractionDigits };
    return (new Intl.NumberFormat('en-US', opts)).format(val);
}

/**
 * If the string is a 10-based number, convert the string to a number.
 * Otherwise, keep the string as is.
 * 
 * @param {String} str 
 * @returns {String|Number}
 */
export function toNumberIfNumber(str) {
    let num = parseInt(str, 10);
    return num.toString()===str ? num : str;
}

/**
 * Capitalize first letter
 * 
 * @param {String} str 
 * @returns {String}
 */
export function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Eg, pluralizeAndFormat('box', 1234) => '1,234 boxes'
 * 
 * @param {String} word 
 * @param {Number} number 
 * @returns {String}
 */
export function pluralizeAndFormat(word, number) {
    return pluralize(word, toNumberIfNumber(toDecimal(number)), true);
}

/**
 * 
 * @param {*} str 
 * @param {*} count 
 * @param {*} addon 
 * @param {*} trim 
 * @returns 
 */
export function maxLen(str, count, addon='&hellip;', trim=true) {
    str = HELPER.sanitize(str).trim();
    addon = HELPER.sanitize(addon).trim();

    if(str.length > count) {
        return str.substr(0, count-addon.length) + (addon || '');
    } else {
        return str;
    }
}