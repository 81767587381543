import * as HELPER from '../../helpers.js';
import OffersController from '../../controllers/offers.js';
import GamesMap, { IDX as IDXG } from '../../models/games/map.js';
import OffersMap, { IDX as IDXO } from '../../models/offers/map.js';

export default class DataSource {
    static json = [];
    static offerData = [];
    static gameData = [];

    static async load() {
        try {
            const response = await fetch('/offers/'+(OffersController.getRoundedTimestamp(Date.now())+'.js'), {
                headers: { Authorization:HELPER.getAuthToken() }
            });
            this.json = await response.json();
        } catch(err) {}
    
        //fall back to client-side/browser-based lookup
        if(!this.json || (!HELPER.IS_LOCAL && Math.round((Date.now()-new Date(this.json.ts))/HELPER.MIN_IN_MS)>35)) {
            this.json = await OffersController.refreshData(null);
        }
        
        let gamesMap = new GamesMap(this.json.games);
        gamesMap.forEach((game, id) => this.gameData.push(gamesMap.entryToFrontendJSON(id)));
    
        let offersMap = new OffersMap(this.json.offers);
        offersMap.forEach((offer, id) => this.offerData.push(offersMap.entryToFrontendJSON(id, gamesMap)));
    
        return [this.json, this.offerData, this.gameData];
    }

    static getUniqueValues(field, opts={}) {
        const sortFn = opts.sortFn ?? null;
        const formatFn = opts.formatFn ?? null;
        const filterFn = opts.filterFn ?? null;
        const addlFields = opts.addlFields ?? [];

        let data = (Object.hasOwn((this.gameData[0] || {}), field))
            ? this.gameData
            : this.offerData;

        //remove dups
        data = [...new Map(data.map(row => [row[field], row])).values()];
        
        if(typeof filterFn === 'function') {
            data = data.filter(filterFn);
        }

        if(typeof formatFn === 'function') {
            data.forEach(row => row[field] = formatFn(row[field]));
        }

        if(sortFn === true) {
            data.sort((a,b) => a[field].localeCompare(b[field]));
        } else if(typeof sortFn === 'function') {
            data.sort(sortFn);
        }

        if(addlFields.length) {
            const fields = [field, ...addlFields];
            const fieldFilter = (obj, keys) => Object.fromEntries(keys.filter(key => key in obj).map(key => [key, obj[key]]));
            return data.map(row => fieldFilter(row, fields));
        } else {
            return data.map(row => row[field]);
        }
    }

    static getAllDaysOfTheWeek() {
        return Array(7).fill(new Date()).map((d,i) => new Date(d.setDate(d.getDate()-d.getDay()+i)).toLocaleDateString('en-us', { weekday:"short" }));
    }

}