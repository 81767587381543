import * as CONFIG from '../../configs.js';
import * as HELPER from '../../helpers.js';
import getColumnsDefs from './column-defs.js';
import DataSource from './data-source.js';
import BookmarksStorage from './bookmarks-storage.js';
import tippy from 'tippy.js';

const clone = (obj) => JSON.parse(JSON.stringify(obj));
const log = (...args) => console.log(...clone(args));

export const getUrlParamFilters = (url) => {
	const params = Object.fromEntries((new URL(url)).searchParams);
	for(const k in params) {
		params[k] = params[k].split(CONFIG.UI.URL.PARAM_SEPARATOR.DEFAULT);
	}
	return { ...CONFIG.UI.FILTER.DEFAULTS, ...params };
};

const applyFiltersToUI = (urlFilters) => {
	const checkboxes = Array.from(document.querySelectorAll('#filters input[type=checkbox]'));
	checkboxes.forEach(checkbox => {
		const oldValue = checkbox.checked;
		if(checkbox.name in urlFilters && urlFilters[checkbox.name].includes(checkbox.value)) {
			checkbox.checked = true;
		} else {
			checkbox.checked = false;
		}
		if(oldValue != checkbox.checked) {
			checkbox.dispatchEvent(new Event('change'));
		}
	});
	
	const textboxes = Array.from(document.querySelectorAll('#filters input:not([type=checkbox]), #filters select'));
	textboxes.forEach(textbox => {
		const oldValue = textbox.value;
		if(textbox.name in urlFilters) {
			textbox.value = urlFilters[textbox.name]; //.join(URL_PARAM_JOINER); //typical use case: an array with a single value (qty is special)
		} else {
			textbox.value = '';
		}
		if(oldValue != textbox.value) {
			textbox.dispatchEvent(new Event('change'));
		}
	});
}

const getFiltersFromUI = (filters={}) => {
	const checkboxes = Array.from(document.querySelectorAll('#filters input[type=checkbox]'));
	checkboxes.forEach(checkbox => {
		if(checkbox.checked) {
			let newValue = [].concat(
				filters?.[checkbox.name] ?? [],
				checkbox.value
			);
			filters[checkbox.name] = [...new Set(newValue)]; //remove dups
		}
	});

	const textboxes = Array.from(document.querySelectorAll('#filters input:not([type=checkbox]), #filters select'));
	textboxes.forEach(textbox => {
		if(textbox.value) {
			filters[textbox.name] = [textbox.value];
		}
	});

	return filters;
}

const addFiltersToURL = (filters) => {
	let url = new URL(window.location);
	let params = new URLSearchParams();
	for(let [k, v] of Object.entries(filters)) {
		v = Array.isArray(v) ? v.sort().join(CONFIG.UI.URL.PARAM_SEPARATOR.DEFAULT) : v;
		if(k in CONFIG.UI.FILTER.DEFAULTS && CONFIG.UI.FILTER.DEFAULTS[k]==v) {
			continue;
		}
		params.set(k, v);
	}
	params.sort();
	url.search = params;
	history.replaceState({}, "", url);
	return Object.fromEntries(params);
}

const updateFilterBtn = (urlParams) => {
	const filterCnt = Object.keys(urlParams).length;
	document.querySelectorAll('.navbar .btn-filters-text').forEach(btn => {
		if(filterCnt) {
			btn.innerHTML = `Filters (${filterCnt})`;
			btn.closest('.button').classList.add('is-danger');
			btn.closest('.button').classList.remove('is-radius');
			document.getElementById('bookmark-form').classList.remove('is-hidden');
			document.getElementById('btn-filters-clear').parentElement.classList.remove('is-hidden');
		} else {
			btn.innerHTML = 'Filters';
			btn.closest('.button').classList.remove('is-danger');
			btn.closest('.button').classList.add('is-radius');
			document.getElementById('bookmark-form').classList.add('is-hidden');
			document.getElementById('btn-filters-clear').parentElement.classList.add('is-hidden');
		}
	});
}

const sortOffers = (offers) => {
	offers.sort((a, b) => {
		if(a.price > b.price) return 1;
		if(a.price < b.price) return -1;

		const rating = a.rating.localeCompare(b.rating);
		if(rating !== 0) return rating;

		const row = String(a.row).localeCompare(String(b.row));
		if(row !== 0) return row;

		if(a.qty > b.qty) return 1;
		if(a.qty < b.qty) return -1;

		const section_name = String(a.section_name).localeCompare(String(b.section_name));
		if(section_name !== 0) return section_name;

		return 0;
	});
	return offers;
}

const applyFilters = (offers, urlFilters=null) => {
	if(urlFilters) {
		applyFiltersToUI(urlFilters);
	} else {
		urlFilters = {};
	}
	
	let filters = getFiltersFromUI(clone(urlFilters));
	let urlParams = addFiltersToURL(filters);
	updateFilterBtn(urlParams);

	offers = filterData(offers, filters);
	sortOffers(offers);

	log(
		'APPLY_FILTERS - ',
		'URL:', urlFilters,
		'URL+Input:', filters,
		'New URL:', urlParams
	);
	return [urlParams, filters, offers];
}

const filterData = (offers, filters) => {
	const checkboxFilterNames = (() => {
		const checkboxes = Array.from(document.querySelectorAll('#filters input[type=checkbox]'));
		return new Set(checkboxes.map(checkbox => checkbox.name));
	})();

	const typableFilterNames = (() => {
		const inputs = Array.from(document.querySelectorAll('#filters input[type=text], #filters input[type=number]'));
		return new Set(inputs.map(input => input.name));
	})();

	for(const [k, v] of Object.entries(filters)) {
		if(k === 'price') {
			offers = offers.filter(offer => offer[k] <= v[0]);
		} else if(k === 'qty') {
			let [min, max] = v[0].split(CONFIG.UI.URL.PARAM_SEPARATOR.QTY);
			min = Number(min);
			max = (max==CONFIG.UI.FILTER.QTY_MAX) ? CONFIG.UI.OFFERS.QTY_MAX : Number(max);
			const range = [...Array(max-min+1).keys()].map(i => i + min);
			offers = offers.filter(offer => {
				return offer.splits.some(x => range.includes(x));
			});
		} else if(checkboxFilterNames.has(k)) {
			offers = offers.filter(offer => v.includes(offer[k]));
		} else if(typableFilterNames.has(k)) {
			offers = offers.filter(offer => {
				if(v[0] === v[0].replace(/\D+/g, '')) {
					return String(offer[k]).replace(/\D+/g, '') === v[0];
				}
				return String(offer[k]).toLowerCase().includes(v[0].toLowerCase());
			});
		} else {
			offers = offers.filter(offer => String(offer[k]).toLowerCase() == v[0].toLowerCase());
		}
	};
	return offers;
}

const updateCountElem = (offerData) => {
	let cntElem = document.getElementById('count');
	let ticketCount = offerData.reduce((total,offer)=>total+offer.qty, 0);
	cntElem.classList.add('has-text-grey-lighter');
	cntElem.innerHTML = `<span class="is-hidden-touch">${HELPER.pluralizeAndFormat('ticket', ticketCount)} in</span> ${HELPER.pluralizeAndFormat('listing', offerData.length)}`;
	setTimeout(()=>cntElem.classList.remove('has-text-grey-lighter'), 50);
}

const drawTable = (urlParams, filters, offerData) => {
	log('DRAWING TABLE');
	const truncatedOfferData = offerData.slice(0, CONFIG.UI.OFFERS.ROWS_MAX);

	const accessFilter = filters.access ? filters.access[0] : null;
	const columnsDefs = getColumnsDefs(DataSource.json)
		.filter(def => (def.visibility===true || def.visibility===undefined || [].concat(def.visibility).includes(accessFilter)));
	
	const getAlignClass = (align) => ['left', 'centered', 'right', 'justified'].includes(align) ? 'has-text-'+align : '';

	HELPER.replaceHtml('table-wrapper', `
		<table id="table">
			<thead>
				<tr>
				${
					columnsDefs
						.map(def => {
							const classes = ['is-uppercase', getAlignClass(def.align)].concat(def.classes || []);
							const isFiltered = [def.field].concat(def.filterReps || []).filter(field => Object.hasOwn(urlParams,field)).length;
							const filter = isFiltered ? '<span class="icon mx-0 has-text-danger"><i class="far fa-filter"></i></span>' : '';
							const icons = (def.icons || []).map(icon => `<span class="icon mx-0"><i class="far ${icon}"></i></span>`);
							if(def.headerTooltip) icons.push(`<span class="icon mx-0" data-tooltip="${ HELPER.escapeHtml(HELPER.sanitize(def.headerTooltip)) }"><i class="far fa-question-circle"></i></span>`);
							return `<th class="${ classes.join(' ') }"><span class="icon-text">${filter}<span class="col-title">${def.title}</span><span class="col-title-short is-hidden">${def.title_short || def.title}</span>${icons.join('')}</span></th>`;
						}).join('')
				}
				</tr>
			</thead>
			<tbody>
			${
				truncatedOfferData.map(offer => `<tr>
					${
						columnsDefs
							.map(def => {
								let classes = [getAlignClass(def.align)].concat(def.classes || []);
								let value = def.formatter ? def.formatter(offer[def.field], offer) : offer[def.field];
								return `<td class="${ classes.join(' ') }">${ value }</td>`;
							}).join('')
					}
				</tr>`).join('')
			}
			</tbody>
			<tfoot>
			${
				(offerData.length > CONFIG.UI.OFFERS.ROWS_MAX)
					? `<tr><td colspan="100%" class="p-4 is-warning has-text-centered has-text-weight-bold">Showing the first ${ HELPER.toDecimal(CONFIG.UI.OFFERS.ROWS_MAX) } of ${ HELPER.toDecimal(offerData.length) } rows. Use filters to narrow your search.</td></tr>`
					: ``
			}
			</tfoot>
		</table>
	`);

	updateCountElem(offerData);
	
	tippy('[data-tooltip]', {
		content: ref => ref.getAttribute('data-tooltip'),
		allowHTML: true
	});
	tippy('[data-seatview-tooltip]', {
		content: ref => `<img src="https://dniv1df92rxmc.cloudfront.net/Seatics/2019_TDGarden_NHL_Bruins/400x300/${ref.getAttribute('data-seatview-tooltip')}.jpg" srcset="https://dniv1df92rxmc.cloudfront.net/Seatics/2019_TDGarden_NHL_Bruins/800x600/${ref.getAttribute('data-seatview-tooltip')}.jpg 2x" referrerpolicy="no-referrer" width="400" height="300">`,
		allowHTML: true,
		maxWidth: 'none',
		//onCreate: (instance) => { instance.popper.querySelector('.tippy-content').style.padding=0; }
	});

	document.dispatchEvent(new Event('OfferTableContentChanged'))
}

let populateTableTimerId;
export const populateTable = (msDelay, urlFilters) => {
	clearTimeout(populateTableTimerId);

	const run = (urlFilters) => {
		let [params, filters, offerData] = applyFilters(DataSource.offerData, urlFilters);
		drawTable(params, filters, offerData);
	};

	if(msDelay) {
		populateTableTimerId = setTimeout(run, msDelay, urlFilters);
	} else {
		run(urlFilters);
	}
}

export const initBookmarks = async (DataSource) => {
	const gDriveSyncIsOff = document.getElementById('gdrive-sync-is-off');
	const gDriveSyncIsOn = document.getElementById('gdrive-sync-is-on');
	const storage = await BookmarksStorage.build();

	const reload = () => {
		let listElem = document.getElementById('bookmark-list');
		if(storage.bookmarks.length) {
			listElem = HELPER.replaceHtml('bookmark-list', storage.bookmarks.map(bookmark => {
				if(!bookmark.u || !bookmark.n) return '';
				const offers = filterData(DataSource.offerData, getUrlParamFilters('https://null'+bookmark.u));
				return `
					<tr class="is-clickable" onclick="document.location='${bookmark.u}'">
						<td class="has-text-centered is-vcentered">${HELPER.toDecimal(offers.length)}</td>
						<td class="is-normalcase is-vcentered">${bookmark.n}</td>
						<td><button class="button is-small" data-deletets="${bookmark.t}">
							<span class="icon is-size-6 has-text-grey"><i class="far fa-trash-xmark"></i></span></button>
						</td>
					</tr>`;
			}).join(''));

			listElem.querySelectorAll('[data-deletets]').forEach(elem => {
				elem.addEventListener('click', async (event) => {
					event.stopPropagation();
					await storage.delete(elem.dataset.deletets);
					reload();
				});
			});
		} else {
			HELPER.replaceHtml(listElem, `<tr><td colspan="100%"><span class="dropdown-item is-capitalized is-italic">No saved filters</span></td></tr>`);
		}
	}

	gDriveSyncIsOff.querySelector('button').addEventListener('click', () => {
		storage.googleLogin();
	});

	gDriveSyncIsOn.querySelector('button').addEventListener('click', async () => {
		gDriveSyncIsOff.classList.remove('is-hidden');
		gDriveSyncIsOn.classList.add('is-hidden');
		await storage.googleLogout();
	});

	document.getElementById('bookmark-form').addEventListener('submit', async (event) => {
		event.preventDefault();
		const input = document.getElementById('bookmark-new-name');
		if(!input.value.trim()) {
			input.classList.remove('has-background-danger-light');
			setTimeout(()=>input.classList.add('has-background-danger-light'), 100);
			setTimeout(()=>input.classList.remove('has-background-danger-light'), 300);
		} else {
			await storage.add(input.value, window.location.search);
			input.value = '';
			reload();
		}
	});

	reload();

	document.querySelector('.filters-bookmarks .dropdown-trigger button').disabled = false;
	gDriveSyncIsOn.querySelector('button').disabled = false;
	gDriveSyncIsOff.querySelector('button').disabled = false;

	if(storage.isLoggedIntoGoogle) {
		gDriveSyncIsOn.classList.remove('is-hidden');
		gDriveSyncIsOff.classList.add('is-hidden');
	}
}