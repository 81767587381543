import * as MAP from '../../maps.js';
import * as HELPER from '../../helpers.js';

export const IDX = Object.fromEntries([
    'id', 'tickpick_id', 'opponent', 'start', 'type', 'updated_at', 'history', 'opponent_short', 'opponent_abbrev', 'opponent_logo', 'season'
].map((v,k)=>[v,k]));

export default class GamesMap extends Map {
    #byTickPickId = new Map();
    #frontendData = new Map();

    /**
     * @param {Array} arr
     * @returns {self}
     */
    constructor(arr=[]) {
        super(); //explicitly NOT passing arr
        arr.forEach(row => this.add(row));
    }

    /**
     * @returns {Array}
     */
    toJSON() {
        return Array.from(this.values()).sort((a, b) => a[IDX.start] - b[IDX.start]);
    }

    /**
     * @param {Array} arr
     * @returns {self}
     */
    static fromJSON(arr) {
        return new this(arr);
    }

    /**
     * @param {Object}
     * @returns {self}
     */
    add(value) {
        this.#byTickPickId.set(value[IDX.tickpick_id], value);
        return this.set(value[IDX.id], value);
    }

    /**
     * @param {Number} key
     * @returns {Object}
     */
    getByTickpickId(key) {
        return this.#byTickPickId.get(key);
    }

    /**
     * @param {Object}
     * @returns {self}
     */
    merge(newGame) {
        let game = this.getByTickpickId(newGame[IDX.tickpick_id]);
        if(game) {
            newGame[IDX.id] = game[IDX.id];

            game[IDX.history] ??= [];
            newGame[IDX.history] ??= [];
            newGame[IDX.history] = [ ...game[IDX.history], ...newGame[IDX.history] ];

            if(game[IDX.start] != newGame[IDX.start]) {
                newGame[IDX.history].push([game[IDX.updated_at], game[IDX.start]]);
            }
        } else if(this.has(newGame[IDX.id])) {
            let newId = this.size;
            while(this.has(newId)) newId++;
            newGame[IDX.id] = newId;
        }
        this.add(newGame);
        return newGame;
    }

    /**
     * Transform entry data to an object
     * 
     * @param {Number}
     * @returns {Object}
     */
    entryToObject(id) {
        const obj = {};
        const data = this.get(id);
        for(const [k,v] of Object.entries(IDX)) {
            obj[k] = data[v] || '';
        }
        return obj;
    }

    /**
     * Transform object data to format that the frontend needs to injest it
     * 
     * @param {Number}
     * @returns {Object}
     */
    entryToFrontendJSON(id) {
        if(!this.#frontendData.get(id) && this.get(id)) {
            const obj = this.entryToObject(id);
    
            if(obj.start) {
                let d = new Date(obj.start);
                obj.day = d.toLocaleDateString('en-us', { weekday:"short" });
                obj.month = d.toLocaleDateString('en-us', { month:"short" });
                obj.date = d.toLocaleDateString('en-us', { weekday:"short", month:"short", day:"2-digit" });
                obj.time = (new Intl.DateTimeFormat("en-us", { timeStyle:"short" })).format(d);
                obj.hour = (new Intl.DateTimeFormat("en-us", { hour:"numeric" })).format(d);
            }
    
            obj.type = HELPER.mapper(MAP.GAME_TYPE, 'ID', 'TEXT', obj.type);
            this.#frontendData.set(id, obj);
        }

        return this.#frontendData.get(id);
    }
}