import * as CONFIG from '../../configs.js';
import * as HELPER from '../../helpers.js';
import BookmarksGoogleDrive from './bookmarks-google-drive.js';

export default class BookmarksStorage {
    #namespace = 'bookmarks';
    #bookmarks;
    #googleDrive;
    #isLoggedIntoGoogle;

    static async build() {
        const obj = new this(true);
        obj.#googleDrive = new BookmarksGoogleDrive();
        obj.#isLoggedIntoGoogle = await obj.#googleDrive.isLoggedIn();

        try {
            obj.#bookmarks = JSON.parse(localStorage.getItem(obj.#namespace) || '[]');
        } catch(e) {
            obj.#bookmarks = [];
        }

        await obj.#sync();
        obj.#save();
        return obj;
    }

    constructor(is_builder) {
        if(!is_builder) {
            throw new Error('Cannot be called directly; use build()');
        }
    }

    googleLogin() {
        this.#googleDrive.startUserAuth();
    }

    async googleLogout() {
        await this.#googleDrive.logout();
    }

    get bookmarks() {
        return this.#bookmarks;
    }

    get isLoggedIntoGoogle() {
        return this.#isLoggedIntoGoogle;
    }

    async add(name, url) {
        this.#bookmarks.push({
            t: HELPER.adler32(String(Date.now())),
            n: name.trim(),
            u: url
        });
        await this.#sync();
        this.#save();
    }

    async delete(ts) {
        const bookmark = this.#bookmarks.find(bookmark => bookmark.t == ts);
        if(bookmark) {
            delete bookmark.u;
            delete bookmark.n;
        }
        await this.#sync();
        this.#save();
    }

    async #sync() {
        if(this.#isLoggedIntoGoogle) {
            this.#sort();
            this.#bookmarks = await this.#googleDrive.sync(this.#bookmarks);
        }
    }

    #save() {
        this.#sort();
        localStorage.setItem(this.#namespace, JSON.stringify(this.#bookmarks));
    }

    #sort() {
        this.#bookmarks.sort((a, b) => {
			if(!a.n) return 1;
			if(!b.n) return -1;
			return a.n.localeCompare(b.n)
		});
    }

}