
export default function getVrSeatViewUrl(offer) {
    let row = offer.row;
    let path;

    let key = offer.section_name
        .toUpperCase()
        .replace('CLUB', 'CLB')
        .replace('RAFT', 'RAF')
        .replaceAll(' ', '');

    if(key in data) {
        let rows = Object.keys(data[key]);

        // if there is only one item
        if(rows.length === 1) {
            path = data[key][rows[0]];

        // if there is a 'GA' item
        } else if(rows.includes('GA')) {
            path = data[key]['GA'];

        // if 1-3 digit OR single letter
        } else if(/^(\d{1,3}|[A-Z])$/.test(row)) {
            row = alphaRowToNumRow(row);

            rows = alphaRowToNumRow(rows)
                .map(str => Number(str))
                .filter(num => num && !Number.isNaN(num));

            let closestNumRow = rows.sort((a,b) => Math.abs(row - a) - Math.abs(row - b) )[0];
            let closestAlphaRow = numRowToAlphaRow(closestNumRow);

            if(rows.includes(closestNumRow)) {
                path = data[key][closestNumRow];
            } else if(rows.includes(closestAlphaRow)) {
                path = data[key][closestAlphaRow];
            }
        }

        if(!path) {
            path = Object.values(data[key])[0];
        }

        // use mobile URL because it will auto show desktop to desktop and mobile to mobile
        // return `https://bruins.io-media.com/#/vr/${path}/d`;
        return `https://bruins.io-media.com/mobile/index.html#/vr/${path}/d`
    }
    return null;
}

function alphaRowToNumRow(str) {
    const func = (char) => {
        return /^[A-Z]$/.test(char) ? char.charCodeAt(0)-64 : char;
    }
    if(Array.isArray(str)) {
        return str.map(func);
    } else {
        return func(str);
    }
}

function numRowToAlphaRow(num) {
    const func = (num) => {
        return (Number(num) && !Number.isNaN(num)) ? String.fromCharCode(num+64) : num;
    }
    if(Array.isArray(num)) {
        return num.map(func);
    } else {
        return func(num);
    }
}

const data = {
    BAL301:{ 1:'Section/BAL301/1/10', 5:'Section/BAL301/5/7', 9:'Section/BAL301/9/10', 15:'Section/BAL301/15/7', BAR:'Section/BAL301/BAR/4' },
    BAL302:{ 1:'Section/BAL302/1/9', 5:'Section/BAL302/5/6', 9:'Section/BAL302/9/10', 15:'Section/BAL302/15/8', BAR:'Section/BAL302/BAR/6' },
    BAL303:{ 1:'Section/BAL303/1/9', 9:'Section/BAL303/9/10', 15:'Section/BAL303/15/10', BAR:'Section/BAL303/BAR/9' },
    BAL304:{ 1:'Section/BAL304/1/8', 7:'Section/BAL304/7/7', 15:'Section/BAL304/15/14' },
    BAL305:{ 1:'Section/BAL305/1/8', 7:'Section/BAL305/7/7', 15:'Section/BAL305/15/14' },
    BAL306:{ 1:'Section/BAL306/1/8', 7:'Section/BAL306/7/7', 15:'Section/BAL306/15/14' },
    BAL307:{ 1:'Section/BAL307/1/8', 7:'Section/BAL307/7/7', 15:'Section/BAL307/15/14' },
    BAL308:{ 1:'Section/BAL308/1/10', 7:'Section/BAL308/7/7', 15:'Section/BAL308/15/14' },
    BAL309:{ 1:'Section/BAL309/1/9', 7:'Section/BAL309/7/7', 15:'Section/BAL309/15/12' },
    BAL310:{ 1:'Section/BAL310/1/8', 7:'Section/BAL310/7/7', 15:'Section/BAL310/15/14' },
    BAL311:{ 1:'Section/BAL311/1/8', 7:'Section/BAL311/7/7', 15:'Section/BAL311/15/14' },
    BAL312:{ 1:'Section/BAL312/1/8', 7:'Section/BAL312/7/7', 15:'Section/BAL312/15/14' },
    BAL313:{ 1:'Section/BAL313/1/8', 7:'Section/BAL313/7/7', 15:'Section/BAL313/15/14' },
    BAL314:{ 1:'Section/BAL314/1/9', 5:'Section/BAL314/5/6', 9:'Section/BAL314/9/10', 15:'Section/BAL314/15/14' },
    BAL315:{ 1:'Section/BAL315/1/9', 5:'Section/BAL315/5/6', 9:'Section/BAL315/9/10', 15:'Section/BAL315/15/12' },
    BAL316:{ 1:'Section/BAL316/1/10', 5:'Section/BAL316/5/7', 9:'Section/BAL316/9/10', 15:'Section/BAL316/15/10' },
    BAL317:{ 1:'Section/BAL317/1/9', 5:'Section/BAL317/5/6', 9:'Section/BAL317/9/10', 15:'Section/BAL317/15/9' },
    BAL318:{ 1:'Section/BAL318/1/9', 9:'Section/BAL318/9/10', 15:'Section/BAL318/15/10' },
    BAL319:{ 1:'Section/BAL319/1/8', 7:'Section/BAL319/7/7', 15:'Section/BAL319/15/14' },
    BAL320:{ 1:'Section/BAL320/1/8', 7:'Section/BAL320/7/7', 15:'Section/BAL320/15/14' },
    BAL321:{ 1:'Section/BAL321/1/8', 7:'Section/BAL321/7/7', 15:'Section/BAL321/15/14' },
    BAL322:{ 1:'Section/BAL322/1/8', 7:'Section/BAL322/7/7', 15:'Section/BAL322/15/14' },
    BAL323:{ 1:'Section/BAL323/1/9', 7:'Section/BAL323/7/7', 15:'Section/BAL323/15/12' },
    BAL324:{ 1:'Section/BAL324/1/10', 7:'Section/BAL324/7/7', 15:'Section/BAL324/15/12' },
    BAL325:{ 1:'Section/BAL325/1/8', 7:'Section/BAL325/7/7', 15:'Section/BAL325/15/14' },
    BAL326:{ 1:'Section/BAL326/1/8', 7:'Section/BAL326/7/7', 15:'Section/BAL326/15/14' },
    BAL327:{ 1:'Section/BAL327/1/8', 7:'Section/BAL327/7/7', 15:'Section/BAL327/15/14' },
    BAL328:{ 1:'Section/BAL328/1/8', 7:'Section/BAL328/7/7', 15:'Section/BAL328/15/14' },
    BAL329:{ 1:'Section/BAL329/1/9', 5:'Section/BAL329/5/6', 9:'Section/BAL329/9/10', 15:'Section/BAL329/15/10', BAR:'Section/BAL329/BAR/10' },
    BAL330:{ 1:'Section/BAL330/1/9', 5:'Section/BAL330/5/6', 9:'Section/BAL330/9/10' },
    BDRM6L:{ A:'Section/BDRM6L/A/31' },
    BDRM6R:{ A:'Section/BDRM6R/A/80' },
    BRDRM5:{ A:'Section/BRDRM5/A/31' },
    BUDLIGHTTOPSHELFBAR:{ Interior:'Club/BUDLIGHTTOPSHELFBAR/Interior/2' },
    CENTERICE:{ 1:'easteregg/CenterIce/1/1' },
    CLB107:{ B:'Section/CLB107/B/5', H:'Section/CLB107/H/8' },
    CLB109:{ B:'Section/CLB109/B/6', H:'Section/CLB109/H/8' },
    CLB111:{ B:'Section/CLB111/B/6', H:'Section/CLB111/H/10' },
    CLB113:{ B:'Section/CLB113/B/6', H:'Section/CLB113/H/8' },
    CLB115:{ B:'Section/CLB115/B/5', H:'Section/CLB115/H/8' },
    CLB137:{ B:'Section/CLB137/B/5', H:'Section/CLB137/H/8' },
    CLB139:{ B:'Section/CLB139/B/6', H:'Section/CLB139/H/8' },
    CLB141:{ H:'Club/CLB141/H/10', B:'Club/CLB141/B/6' },
    CLB143:{ B:'Section/CLB143/B/6', H:'Section/CLB143/H/8' },
    CLB145:{ B:'Section/CLB145/B/5', H:'Section/CLB145/H/8' },
    DECKR:{ Interior:'Club/DECKR/Interior/1', A:'Section/DECKR/A/33', D:'Section/DECKR/D/48', C:'Section/DECKR/C/35', B:'Section/DECKR/B/46' },
    EXECUTIVEVIEW:{ Exterior:'Club/ExecutiveView/Exterior/1', Interior:'Club/ExecutiveView/Interior/1' },
    FOODHALLANDBAR:{ Interior:'Club/FOODHALLANDBAR/Interior/2' },
    GARDENVIEW:{ Exterior:'Club/GardenView/Exterior/1', Interior:'Club/GardenView/Interior/1' },
    GOAL:{ 1:'easteregg/Goal/1/1', 2:'easteregg/Goal/2/1' },
    HEINEKENBOARDROOM:{ Exterior:'Club/HeinekenBoardroom/Exterior/1', Interior:'Club/HeinekenBoardroom/Interior/1' },
    JAMESONLOUNGE:{ Interior:'Club/JamesonLounge/Interior/1', Exterior:'Club/JamesonLounge/Exterior/1' },
    LOFT01:{ 1:'Section/LOFT01/1/3' },
    LOFT02:{ 1:'Section/LOFT02/1/2' },
    LOFT03:{ 1:'Section/LOFT03/1/3' },
    LOFT04:{ 1:'Section/LOFT04/1/2' },
    LOFT05:{ 1:'Section/LOFT05/1/3' },
    LOFT06:{ 1:'Section/LOFT06/1/2' },
    LOFT07:{ 1:'Section/LOFT07/1/3' },
    LOFT08:{ 1:'Section/LOFT08/1/2' },
    LOFT09:{ 1:'Section/LOFT09/1/3' },
    LOFT10:{ 1:'Section/LOFT10/1/2' },
    LOFT11:{ 1:'Section/LOFT11/1/3' },
    LOFT12:{ 1:'Section/LOFT12/1/2' },
    LOGE1:{ 3:'Section/LOGE1/3/10', 6:'Section/LOGE1/6/10', 11:'Section/LOGE1/11/10', 17:'Section/LOGE1/17/10' },
    LOGE10:{ 1:'Section/LOGE10/1/6', 4:'Section/LOGE10/4/7', 9:'Section/LOGE10/9/11', 12:'Section/LOGE10/12/11', 19:'Section/LOGE10/19/5', 23:'Section/LOGE10/23/8' },
    LOGE11:{ 1:'Section/LOGE11/1/10', 4:'Section/LOGE11/4/10', 9:'Section/LOGE11/9/10', 16:'Section/LOGE11/16/7' },
    LOGE12:{ 1:'Section/LOGE12/1/18', 4:'Section/LOGE12/4/10', 9:'Section/LOGE12/9/10', 16:'Section/LOGE12/16/10' },
    LOGE13:{ 1:'Section/LOGE13/1/10', 4:'Section/LOGE13/4/10', 9:'Section/LOGE13/9/10', 16:'Section/LOGE13/16/8' },
    LOGE14:{ 1:'Section/LOGE14/1/5', 4:'Section/LOGE14/4/5', 9:'Section/LOGE14/9/7' },
    LOGE15:{ 5:'Section/LOGE15/5/5', 12:'Section/LOGE15/12/13', 19:'Section/LOGE15/19/16', 23:'Section/LOGE15/23/26' },
    LOGE16:{ 1:'Section/LOGE16/1/2', 5:'Section/LOGE16/5/3', 12:'Section/LOGE16/12/5', 19:'Section/LOGE16/19/4', 22:'Section/LOGE16/22/7' },
    LOGE17:{ 1:'Section/LOGE17/1/9', 5:'Section/LOGE17/5/9', 9:'Section/LOGE17/9/12', 15:'Section/LOGE17/15/6', 20:'Section/LOGE17/20/4' },
    LOGE18:{ 1:'Section/LOGE18/1/6', 5:'Section/LOGE18/5/9', 9:'Section/LOGE18/9/12', 15:'Section/LOGE18/15/11', 20:'Section/LOGE18/20/10' },
    LOGE19:{ 1:'Section/LOGE19/1/6', 5:'Section/LOGE19/5/8', 12:'Section/LOGE19/12/14', 19:'Section/LOGE19/19/19', 23:'Section/LOGE19/23/28' },
    LOGE2:{ 1:'Section/LOGE2/1/14', 4:'Section/LOGE2/4/10', 9:'Section/LOGE2/9/10', 15:'Section/LOGE2/15/10' },
    LOGE20:{ 1:'Section/LOGE20/1/6', 5:'Section/LOGE20/5/7', 12:'Section/LOGE20/12/10', 22:'Section/LOGE20/22/7' },
    LOGE21:{ 1:'Section/LOGE21/1/5', 4:'Section/LOGE21/4/9', 9:'Section/LOGE21/9/9', 15:'Section/LOGE21/15/10' },
    LOGE3:{ 1:'Section/LOGE3/1/6', 4:'Section/LOGE3/4/7', 9:'Section/LOGE3/9/9', 12:'Section/LOGE3/12/10', 19:'Section/LOGE3/19/5', 23:'Section/LOGE3/23/9' },
    LOGE4:{ 4:'Section/LOGE4/4/4', 5:'Section/LOGE4/5/4', 12:'Section/LOGE4/12/7', 19:'Section/LOGE4/19/5', 23:'Section/LOGE4/23/8' },
    LOGE5:{ 5:'Section/LOGE5/5/3', 12:'Section/LOGE5/12/6', 19:'Section/LOGE5/19/4', 23:'Section/LOGE5/23/9' },
    LOGE6:{ 1:'Section/LOGE6/1/9', 5:'Section/LOGE6/5/9', 12:'Section/LOGE6/12/14', 19:'Section/LOGE6/19/4', 23:'Section/LOGE6/23/8' },
    LOGE7:{ 1:'Section/LOGE7/1/5', 5:'Section/LOGE7/5/10', 12:'Section/LOGE7/12/11', 19:'Section/LOGE7/19/22', 23:'Section/LOGE7/23/30' },
    LOGE8:{ 5:'Section/LOGE8/5/3', 12:'Section/LOGE8/12/5', 22:'Section/LOGE8/22/6' },
    LOGE9:{ 1:'Section/LOGE9/1/4', 5:'Section/LOGE9/5/4', 12:'Section/LOGE9/12/7', 19:'Section/LOGE9/19/5', 23:'Section/LOGE9/23/9' },
    MAINENTRY:{ Interior:'Club/MAINENTRY/Interior/2' },
    MARKETANDBAR:{ Interior:'Club/MARKETANDBAR/Interior/2' },
    RAFSROA:{ GA:'Section/RAF12/B/4' }, // not exact
    RAFSROB:{ GA:'Section/RAF34/B/6' }, // not exact
    RAF10:{ GA:'Section/RAF10/B/8' },
    RAF11:{ GA:'Section/RAF11/B/7' },
    RAF12:{ GA:'Section/RAF12/B/4' },
    RAF13:{ GA:'Section/RAF13/A/13' },
    RAF14:{ GA:'Section/RAF14/B/6' },
    RAF15:{ GA:'Section/RAF15/B/5' },
    RAF16:{ GA:'Section/RAF16/B/12' },
    RAF17:{ GA:'Section/RAF17/A/12' },
    RAF28:{ GA:'Section/RAF28/A/12' },
    RAF29:{ GA:'Section/RAF29/B/12' },
    RAF30:{ GA:'Section/RAF30/B/5' },
    RAF31:{ GA:'Section/RAF31/B/7' },
    RAF32:{ GA:'Section/RAF32/B/5' },
    RAF33:{ GA:'Section/RAF33/A/8' },
    RAF34:{ GA:'Section/RAF34/B/6' },
    RAF35:{ GA:'Section/RAF35/B/7' },
    RAF36:{ GA:'Section/RAF36/B/7' },
    RAF37:{ GA:'Section/RAF37/B/8' },
    RAF38:{ GA:'Section/RAF38/B/5' },
    RAF7:{ GA:'Section/RAF7/B/5' },
    RAF8:{ Interior:'Club/RAF8/Interior/1', B:'Club/RAF8/B/5', GA:'Section/RAF8/B/5' },
    RAF9:{ GA:'Section/RAF9/B/6' },
    SOUTHCLUB:{ Interior:'Club/SOUTHCLUB/Interior/3' },
    STE501:{ GA:'Suite/STE501/501/7' },
    STE502:{ GA:'Suite/STE502/502/6' },
    STE503:{ GA:'Suite/STE503/503/6' },
    STE504:{ GA:'Suite/STE504/504/6' },
    STE505:{ GA:'Suite/STE505/505/6' },
    STE506:{ GA:'Suite/STE506/506/6' },
    STE507:{ GA:'Suite/STE507/507/6' },
    STE508:{ GA:'Suite/STE508/508/6' },
    STE509:{ GA:'Suite/STE509/509/6' },
    STE510:{ GA:'Suite/STE510/510/6' },
    STE511:{ GA:'Suite/STE511/511/7' },
    STE512:{ GA:'Suite/STE512/512/7' },
    STE513:{ GA:'Suite/STE513/513/6' },
    STE514:{ GA:'Suite/STE514/514/6' },
    STE515:{ GA:'Suite/STE515/515/6' },
    STE516:{ GA:'Suite/STE516/516/6' },
    STE519:{ GA:'Suite/STE519/519/6' },
    STE520:{ GA:'Suite/STE520/520/6' },
    STE521:{ GA:'Suite/STE521/521/6' },
    STE522:{ GA:'Suite/STE522/522/6' },
    STE523:{ GA:'Suite/STE523/523/6' },
    STE524:{ GA:'Suite/STE524/524/6' },
    STE529:{ GA:'Suite/STE529/529/6' },
    STE530:{ GA:'Suite/STE530/530/6' },
    STE531:{ GA:'Suite/STE531/531/6' },
    STE532:{ GA:'Suite/STE532/532/6' },
    STE533:{ GA:'Suite/STE533/533/6' },
    STE534:{ GA:'Suite/STE534/534/6' },
    STE537:{ GA:'Suite/STE537/537/6' },
    STE538:{ GA:'Suite/STE538/538/6' },
    STE539:{ GA:'Suite/STE539/539/6' },
    STE540:{ GA:'Suite/STE540/540/6' },
    STE541:{ GA:'Suite/STE541/541/7' },
    STE542:{ GA:'Suite/STE542/542/7' },
    STE543:{ GA:'Suite/STE543/543/6' },
    STE544:{ GA:'Suite/STE544/544/6' },
    STE545:{ GA:'Suite/STE545/545/6' },
    STE546:{ GA:'Suite/STE546/546/6' },
    STE547:{ GA:'Suite/STE547/547/6' },
    STE548:{ GA:'Suite/STE548/548/6' },
    STE549:{ GA:'Suite/STE549/549/6' },
    STE550:{ GA:'Suite/STE550/550/6' },
    STE551:{ GA:'Suite/STE551/551/6' },
    STE552:{ GA:'Suite/STE552/552/8' },
    STE602:{ GA:'Suite/STE602/602/7' },
    STE603:{ GA:'Suite/STE603/603/7' },
    STE604:{ GA:'Suite/STE604/604/7' },
    STE605:{ GA:'Suite/STE605/605/7' },
    STE606:{ GA:'Suite/STE606/606/7' },
    STE607:{ GA:'Suite/STE607/607/7' },
    STE608:{ Interior:'Suite/STE608/Interior/1', GA:'Suite/STE608/608/7' },
    STE609:{ GA:'Suite/STE609/609/7' },
    STE610:{ GA:'Suite/STE610/610/7' },
    STE611:{ GA:'Suite/STE611/611/9' },
    STE612:{ GA:'Suite/STE612/612/9' },
    STE613:{ GA:'Suite/STE613/613/7' },
    STE614:{ GA:'Suite/STE614/614/7' },
    STE615:{ GA:'Suite/STE615/615/7' },
    STE616:{ GA:'Suite/STE616/616/7' },
    STE617:{ GA:'Suite/STE617/617/7' },
    STE618:{ GA:'Suite/STE618/618/7' },
    STE619:{ GA:'Suite/STE619/619/7' },
    STE620:{ GA:'Suite/STE620/620/7' },
    STE621:{ GA:'Suite/STE621/621/7' },
    STE632:{ GA:'Suite/STE632/632/7' },
    STE633:{ GA:'Suite/STE633/633/7' },
    STE634:{ GA:'Suite/STE634/634/7' },
    STE635:{ GA:'Suite/STE635/635/7' },
    STE636:{ GA:'Suite/STE636/636/7' },
    STE637:{ GA:'Suite/STE637/637/7' },
    STE638:{ GA:'Suite/STE638/638/7' },
    STE639:{ GA:'Suite/STE639/639/7' },
    STE640:{ GA:'Suite/STE640/640/7' },
    STE641:{ GA:'Suite/STE641/641/6' },
    STE642:{ GA:'Suite/STE642/642/6' },
    STE643:{ GA:'Suite/STE643/643/7' },
    STE644:{ GA:'Suite/STE644/644/7' },
    STE645:{ GA:'Suite/STE645/645/7' },
    STE646:{ GA:'Suite/STE646/646/7' },
    STE647:{ GA:'Suite/STE647/647/7' },
    STE648:{ GA:'Suite/STE648/648/7' },
    STE649:{ GA:'Suite/STE649/649/7' },
    STE650:{ GA:'Suite/STE650/650/7' },
    STU1:{ GA:'Section/STU1/GA/5' },
    STU2:{ GA:'Section/STU2/GA/8' },
    STU3:{ GA:'Section/STU3/GA/4' },
    STU4:{ GA:'Section/STU4/GA/7' },
    STU5:{ Interior:'Club/STU5/Interior/1', GA:'Club/STU5/GA/5' },
    STU6:{ GA:'Section/STU6/GA/7' },
    THELOFTS:{ Exterior:'Club/TheLofts/Exterior/1', Interior:'Club/TheLofts/Interior/1'}
};