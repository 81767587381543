import * as CONFIG from '../../configs.js';
import * as HELPER from '../../helpers.js';
import GoogleApiTokenStorage from './google-api-token-storage.js';

export default class BookmarksGoogleDrive {
    #filename = 'bookmarks.json';
    #fileId;
    #tokenStore;

    startUserAuth() {
        this.#tokenStore.startUserAuth();
    }

    async logout() {
        await this.#tokenStore.revoke();
    }

    async isLoggedIn() {
        if(!this.#tokenStore) {
            this.#tokenStore = await GoogleApiTokenStorage.build();
        }
        if(!this.#tokenStore.access_token) {
            return false;
        }
        return true;
    }

    async sync(localBookmarks) {
        if(!this.isLoggedIn()) {
            return localBookmarks;
        }

        const cloudBookmarks = await this.#pull();
        let mergedBookmarks = this.#merge(localBookmarks, cloudBookmarks);
        this.#push(mergedBookmarks);

        return mergedBookmarks;
    }

    #merge(local, cloud) {
        let merged = (cloud || []).concat(local || []);

        //remove dups
        merged = [...new Map(merged.map(bookmark => {
            return bookmark && bookmark.t ? [bookmark.t, bookmark] : [-1, {}];
        })).values()];

        //remove anything problematic
        merged = merged.filter(bookmark => bookmark.t);

        return merged;
    }

    async #getFileId() {
        if(!this.#fileId) {
            const endpoint = 'https://www.googleapis.com/drive/v3/files' + '?' + new URLSearchParams({
                spaces: 'appDataFolder',
                pageSize: 10,
                fields: 'files(id, name)'
            });
            const json = await HELPER.fetchRespJSON(endpoint, {
                method:'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.#tokenStore.access_token
                }
            });

            if(json === false) {
                this.logout();
            } else if(json.files && json.files.length) {
                const bookmarkFile = json.files.find(file => file.name==this.#filename);
                if(bookmarkFile) {
                    this.#fileId = bookmarkFile.id;
                }
            }
        }
        return this.#fileId;
    }

    async #pull() {
        let resp;

        const fileId = await this.#getFileId();
        if(fileId) {
            const endpoint = 'https://www.googleapis.com/drive/v3/files/' + fileId + '?' + new URLSearchParams({
                alt: 'media'
            });
            resp = await fetch(endpoint, {
                method:'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.#tokenStore.access_token
                }
            });
        }

        const json = await resp.json();
        return json || false;
    }

    async #push(data) {
        const fileId = await this.#getFileId();
        const fileContent = JSON.stringify(data);
        const file = new Blob([fileContent], {type: 'application/json'});
        const metadata = {
            'name': this.#filename,
            'mimeType': 'application/json',
        };
        if(!fileId) {
            metadata.parents = ['appDataFolder'];
            metadata.spaces = 'appDataFolder';
        }
        const form = new FormData();
        form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
        form.append('file', file);
    
        const url = fileId
            ? `https://www.googleapis.com/upload/drive/v3/files/${fileId}?uploadType=multipart&fields=id`
            : 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id';
    
        const resp = await fetch(url, {
            method: (fileId ? 'PATCH' : 'POST'),
            headers: new Headers({ 'Authorization': 'Bearer ' + this.#tokenStore.access_token }),
            body: form,
        });
        const json = await resp.json();
        this.#fileId = json.id;
    }

}