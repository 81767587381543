/**
 * Game type
 * @type {Object}
 */
export const GAME_TYPE = {
    UNKNOWN:        { ID:0, TEXT:'Other' },
    PRESEASON:      { ID:1, TEXT:'Preseason' },
    REGULAR:        { ID:2, TEXT:'Regular' },
    PLAYOFFS:       { ID:3, TEXT:'Playoffs' },
    PLAYOFFS_TBD:   { ID:4, TEXT:'Playoffs (TBD)' },
};

/**
 * Side of area/ice
 * @type {Object}
 */
export const SIDE = {
    UNKNOWN:    { ID:0, TEXT:'Other' },
    MIDDLE:     { ID:1, TEXT:'Center' },
    END:        { ID:2, TEXT:'End' },
    CORNER:     { ID:3, TEXT:'Corner' },
};

/**
 * Level in area
 * @type {Object}
 */
export const LEVEL = {
    UNKNOWN:    { ID:0, PREFIX:'UNKN', TEXT:'Other' },
    LOGE:       { ID:1, PREFIX:'LOGE', TEXT:'Loge' },
    CLUB:       { ID:2, PREFIX:'CLUB', TEXT:'Club' },
    SUITE:      { ID:3, PREFIX:'STE', TEXT:'Suite' },
    BALCONY:    { ID:4, PREFIX:'BAL', TEXT:'Balcony' },
    RAFTER:     { ID:5, PREFIX:'RAFT', TEXT:'Rafters' },
    RAFT_STUD:  { ID:6, PREFIX:'RFST', TEXT:'Rafters Studio' },
};

/**
 * Section of area
 * @type {Object}
 */
export const SECTION = {
    UNKNOWN:    { ID:0, TEXT:'Unknown' },
};

/**
 * Ticket delivery rules
 * @type {Object}
 */
export const DELIVERY = {
    UNKNOWN:    { ID:0, CODE:'_', TEXT:'Unknown' },
    E_TRANFER:  { ID:1, CODE:'f', TEXT:'Electronic/mobile transfer' },
    E_PDF:      { ID:2, CODE:'e', TEXT:'Electronic (PDF) tickets' },
    PAPER:      { ID:3, CODE:'s', TEXT:'Paper (FedEx) tickets' }
};

/**
 * Ticket disclosures
 * @type {Object}
 */
export const DISCLOSURES = {
    UNKNOWN:    { ID:0,  CODE:'_', TEXT:'Unknown' },
    VAX:        { ID:1,  CODE:'vax', TEXT:'Vaccinated section' },
    OBST_VIEW:  { ID:2,  CODE:'o', TEXT:'Limited or obstructed view' },
    ADA:        { ID:3,  CODE:'ada', TEXT:'Wheelchair accessible' },
    WHEELCHAIR: { ID:4,  CODE:'wc', TEXT:'Wheelchair only' },
    AISLE:      { ID:5,  CODE:'a', TEXT:'Aisle Seats' },
    AGE21:      { ID:6,  CODE:'21', TEXT:'21 and over section' },
    REAR:       { ID:7,  CODE:'sr', TEXT:'Side or rear view' },
    OBST_LEG:   { ID:8,  CODE:'l', TEXT:'Restricted leg room' },
    NO_ALCOHOL: { ID:9,  CODE:'b', TEXT:'Alcohol-free section' },
    PARK_ONLY:  { ID:10, CODE:'pk', TEXT:'Parking Pass Only' },
    PARK_INC:   { ID:11, CODE:'ppi', TEXT:'Parking Pass Included' },
    NO_TICKET:  { ID:12, CODE:'na', TEXT:'Not an Event Ticket' },
    VIP:        { ID:13, CODE:'v', TEXT:'VIP Access Included' }
};

/**
 * Ticket access
 * @type {Object}
 */
export const ACCESS = {
    EVENT:            { ID:0, TEXT:'Game' },
    EVENT_XFER:       { ID:1, TEXT:'Game (e-transfer)', ALIAS:0 },     // @deprecated
    EVENT_PDF:        { ID:2, TEXT:'Game (PDF ticket)', ALIAS:0 },     // @deprecated
    EVENT_PHYS:       { ID:3, TEXT:'Game (paper ticket)', ALIAS:0 },   // @deprecated
    EVENT_WHEELCHAIR: { ID:4, TEXT:'Game (wheelchair only)' },
    PARKING:          { ID:8, TEXT:'Parking' },
    LOUNGE:           { ID:9, TEXT:'Legends Club' }
};