/**
 * Get all files, filtered by prefix
 * 
 * @see https://developers.cloudflare.com/r2/api/workers/workers-api-reference/#r2listoptions
 * @param {*} bucket (env.BUCKET)
 * @param {String} prefix
 * @param {Number} max
 * @param {Object} opts
 * @returns {Object}
 */
export async function listR2Objects(bucket, prefix=null, max=null, opts={}) {
    if(prefix) opts.prefix = prefix;
    max ??= Infinity;
    opts.limit = Math.min(max, 1000);
    
    const listed = await bucket.list(opts);

    let truncated = listed.truncated;
    let cursor = truncated ? listed.cursor : undefined;
    while(truncated && listed.objects.length<max) {
        opts.limit = Math.min(max-listed.objects.length, 1000);
        const next = await bucket.list({ ...opts, cursor:cursor });
        listed.objects.push(...next.objects);
        truncated = next.truncated;
        cursor = next.cursor
    }

    return listed;
}

/**
 * Sort array (typically an R2Objects type) by a metadata tag.
 * Return is the reference to the original array, now sorted. 
 * Note that the array is sorted in place, and no copy is made.
 * 
 * @param {Array} arr 
 * @param {String} tag 
 * @returns {Array}
 */
export function sortR2ListByMetadataTag(arr, tag) {
    return arr.sort((a,b) => a.customMetadata[tag] > b.customMetadata[tag] ? 1 : -1);
}