import * as CONFIG from '../configs.js';
import * as HELPER from '../helpers.js';

/**
 * Create response, potentially with devtools URL
 * @param {*} content 
 * @param {Object} env 
 * @param {Object} headers 
 * @returns {Response}
 */
export function createTextOrJsonResponse(content, env, headers={}) {
    if (content?.constructor.name === 'Response') return content;
    let preContent = '', postContent = '';
    const HTML_OPENER = '<!doctype html><html lang="en"><body><pre style="word-wrap:break-word; white-space:pre-wrap;">';

    if(!(typeof content === 'string')) {
        headers['Content-Type'] ??= 'application/json; charset=UTF-8';
        let newContent = JSON.stringify(content);
        if(newContent && newContent.length < 1048576) { // 1MB
            newContent = JSON.stringify(content, null, '    ');
        }
        content = newContent;
    } else {
        headers['Content-Type'] ??= 'text/html; charset=UTF-8';
        preContent = HTML_OPENER;
        content = HELPER.escapeHtml(content);
    }

    if(HELPER.IS_LOCAL) {
        const devToolsUrl = getDevToolsUrl(env);
        headers['X-DevTools-URL'] = devToolsUrl;
        headers['Content-Type'] = 'text/html; charset=UTF-8';
        preContent = HTML_OPENER;
        content = HELPER.escapeHtml(content);
        postContent = `<script> console.info("%cDebug using %s", "background:#FFFF8D; border:dashed 1px #FBC02D; padding:7px 10px; margin:10px 0;", "${devToolsUrl}"); </script>\n`;
    }

    return new Response(
        preContent + "\n" + content + "\n" + postContent,
        { headers }
    );
}

/**
 * Get URL to remote DevTools
 * @param {Object} env 
 * @returns {String}
 */
export function getDevToolsUrl(env) {
    return 'https://devtools.devprod.cloudflare.dev/js_app?' + new URLSearchParams({
        ws: 'localhost:' + HELPER.getObjKey(env,'INSPECTOR_PORT','') + '/ws',
        debugger: 'true'
    });
}

/**
 * 
 * @param {String|Request} url 
 * @param {Object} opts 
 * @param {Number} retries 
 * @param {Number} timeout in seconds
 * @returns {Array<Request,Promise>}
 */
export function fetchWithRetry(url, opts={}, retries=2, timeout=CONFIG.FETCH_TIMEOUT) {
    if(timeout > 0) {
        opts.signal = AbortSignal.timeout(timeout*HELPER.SEC_IN_MS);
    }
    if(typeof retries !== 'object') {
        retries = { total:retries, remaining:retries }
    }
    const request = new Request(url, opts);
    return fetch(request)
        .then(response => {
            if((typeof response !== 'object' || !response.ok) && retries.remaining > 0) {
                retries.remaining--;
                console.warn(`Fetch retry ${(retries.total-retries.remaining)} of ${retries.total} for ${url}`);
                return fetchWithRetry(url, opts, retries, timeout);
            }
            return [request, response];
        });
}

/**
 * Validate and Get JSON Response; report errors as needed
 * 
 * @param {String|Request} url 
 * @param {Object} opts 
 * @param {String} nameInLog 
 * @param {Number} retries 
 * @param {Number} timeout in seconds
 * @returns {Promise}
 */
export async function fetchRespJSON(url, opts={}, nameInLog='Request', retries=2, timeout=CONFIG.FETCH_TIMEOUT) {
    return fetchWithRetry(url, opts, retries, timeout)
        .then(([req, resp]) => {
            if(typeof resp !== 'object') {
                console.error(nameInLog + ' fetch returned invalid response type');
                console.log(resp);
                return false;
            }
            if (!resp.ok) {
                console.error([
                    `${nameInLog} fetch responded with HTTP ${resp.status}: ${resp.statusText}`,
                    Object.fromEntries(req.headers.entries()),
                    Object.fromEntries(resp.headers.entries())
                ]);
                return false;
            }
            const contentType = resp.headers.get('content-type') || resp.headers.get('Content-Type');
            if(!contentType || contentType.indexOf("application/json")===-1) {
                console.error(`${nameInLog} fetch response is not JSON (instead: ${contentType})`);
                return false;
            }
            return resp.json()
                .catch(error => {
                    console.error([`${nameInLog} json processing threw error`, error.message]);
                    return [];
                });
        })
        .catch(error => {
            console.error([`${nameInLog} fetch threw error`, error.message]);
            return [];
        });
}