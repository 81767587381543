import * as CONFIG from '../../configs.js';
import * as MAP from '../../maps.js';
import * as HELPER from '../../helpers.js';
import HeadersController from '../../controllers/headers.js';
import GamesController from '../../controllers/games.js';
import { IDX as IDXG } from '../games/map.js';
import OffersMap from '../offers/map.js';
import PLimit from 'p-limit';

export default class OffersSource {

    /**
     * Get offers for a single game
     * 
     * @param {Array} game 
     * @returns {Promise}
     */
    static async getOffersForGame(env, game) {
        const offers = [];

        let url = 'https://api.tickpick.com/1.0/listings/internal/event/' + game[IDXG.tickpick_id] + '?' + new URLSearchParams({
            //trackView: 'true',
            //mid: game[IDXG.tickpick_id],
            lid: game[IDXG.tickpick_id]
        });
        let opts = JSON.parse(JSON.stringify(CONFIG.FETCH_OPTS.TICKPICK));
        if(env) {
            opts.headers = await HeadersController.getRandom(env, opts.headers);
        }
        let data = await HELPER.fetchRespJSON(url, opts, 'Game '+game[IDXG.tickpick_id], 2, 10);

        return this.parseOffersForGame(game, data);
    }

    static parseOffersForGame(game, data) {
        const offers = [];

        if(data && Array.isArray(data)) {
            for (let i=0; i<data.length; i++) {
                // if(CONFIG.DISCLOSURE_BLOCKLIST.some(v=> (data[i].d || []).indexOf(v) !== -1)) {
                //     continue;
                // }

                let access = MAP.ACCESS.EVENT.ID;
                if(data[i].d.indexOf(MAP.DISCLOSURES.WHEELCHAIR.CODE) !== -1) {
                    access = MAP.ACCESS.EVENT_WHEELCHAIR.ID;
                } else if(data[i].d.indexOf(MAP.DISCLOSURES.PARK_ONLY.CODE) !== -1 && data[i].d.indexOf(MAP.DISCLOSURES.NO_TICKET.CODE) !== -1) {
                    access = MAP.ACCESS.PARKING.ID;
                } else if(data[i].sid.includes('LEGENDS') || (data[i].d.indexOf(MAP.DISCLOSURES.VIP.CODE) !== -1 && data[i].d.indexOf(MAP.DISCLOSURES.NO_TICKET.CODE) !== -1)) {
                    access = MAP.ACCESS.LOUNGE.ID;
                } else if(data[i].sid.includes('NOT AN EVENT TICKET')) {
                    continue;
                }
                
                let levelName = MAP.LEVEL.UNKNOWN.ID;
                let sectionNum = data[i].d.indexOf(MAP.DISCLOSURES.NO_TICKET.CODE)!==-1 ? 0 : parseInt(data[i].sid.replace(/\D/g,''));
                let sectionName = data[i].sid;
                
                let isRafterStudios = sectionName.match(/(RAF.*)?STU(DIO\s*)?\s*(\d+)/);
                let isRafters = sectionName.match(/RAF(TER|\s*(\d+))/);
                
                if(isRafterStudios) {
                    sectionNum = 700 + (isRafters[3] ? isRafters[3] : 0);
                    levelName = MAP.LEVEL.RAFT_STUD.ID;
                    sectionName = MAP.LEVEL.RAFT_STUD.PREFIX + ' ' + (isRafters[3] ? isRafters[3] : '?');
                } else if(isRafters) {
                    sectionNum = 700 + (isRafters[3] ? isRafters[3] : 0);
                    levelName = MAP.LEVEL.RAFTER.ID;
                    if(data[i].r == 'A' || data[i].r == 'B') {
                        sectionName = MAP.LEVEL.RAFTER.PREFIX + ' SRO ' + data[i].r;
                    } else if(data[i].r == 'SRO') {
                        sectionName = MAP.LEVEL.RAFTER.PREFIX + ' SRO';
                    } else {
                        sectionName = MAP.LEVEL.RAFTER.PREFIX + ' ' + (isRafters[2] ? isRafters[2] : '?');
                    }
                } else if(1 <= sectionNum && sectionNum <= 21) {
                    levelName = MAP.LEVEL.LOGE.ID;
                    sectionName = MAP.LEVEL.LOGE.PREFIX + ' ' + sectionNum;
                } else if(107 <= sectionNum && sectionNum <= 145)  {
                    levelName = MAP.LEVEL.CLUB.ID;
                    sectionName = MAP.LEVEL.CLUB.PREFIX + ' ' + sectionNum;
                } else if(301 <= sectionNum && sectionNum <= 330)  {
                    levelName = MAP.LEVEL.BALCONY.ID;
                    sectionName = MAP.LEVEL.BALCONY.PREFIX + ' ' + sectionNum;
                } else if(500 <= sectionNum && sectionNum <= 699)  {
                    levelName = MAP.LEVEL.SUITE.ID;
                    sectionName = MAP.LEVEL.SUITE.PREFIX + ' ' + sectionNum;
                } else if(sectionName.includes('GARDENVIEW') || sectionName.includes('GARDEN VIEW')) {
                    levelName = MAP.LEVEL.SUITE.ID;
                    sectionName = 'GARDENVIEW';
                } else if(sectionName.includes('SPORTSDECK') || sectionName.includes('SPORTS DECK')) {
                    levelName = MAP.LEVEL.SUITE.ID;
                    sectionName = 'SPORTSDECK';
                } else if(sectionName.includes('BOARDROOM') || sectionName.includes('BOARD ROOM')) {
                    levelName = MAP.LEVEL.SUITE.ID;
                    sectionName = 'BOARDROOM';
                } else if(sectionName.includes('LOFT')) {
                    levelName = MAP.LEVEL.SUITE.ID;
                    sectionName = 'LOFTS';
                } else if(!data[i].e || data[i].r.toUpperCase() == 'PACKAGE') {
                    let sectionNameUpper = data[i].sid.toUpperCase();
                    data[i].n = sectionNameUpper + ' PACKAGE: ' + data[i].n;
                    data[i].r = 'TBD';
                    
                    if(sectionNameUpper == 'CLUB LEVEL') levelName = MAP.LEVEL.CLUB.ID;
                    else if(sectionNameUpper == 'LOWER LEVEL') levelName = MAP.LEVEL.CLUB.ID;
                    else if(sectionNameUpper == 'VISITING TEAM FAN') levelName = MAP.LEVEL.CLUB.ID;
                    else if(sectionNameUpper == 'CENTER ICE') levelName = MAP.LEVEL.CLUB.ID;
                    else if(sectionNameUpper == 'BRUINS FAN') levelName = MAP.LEVEL.CLUB.ID;
                    else if(sectionNameUpper == 'BRUINS SIGNATURE') levelName = MAP.LEVEL.CLUB.ID;
                    else levelName = MAP.LEVEL.UNKNOWN.ID;
                    
                    sectionNum = 0;
                    sectionName = MAP.SECTION.UNKNOWN.TEXT;
                }
                
                let sectionSide = MAP.SIDE.UNKNOWN.ID;
                if(
                    ([1,2,11,12,13,21,109,111,113,139,141,143,509,510,511,512,513,514,539,540,541,542,543,544,609,610,611,612,613,614,639,640,641,642,643,644,301,302,315,316,317,330,702,703,704,705].includes(sectionNum)) ||
                    (data[i].sid == 'BRUINS SIGNATURE') ||
                    (data[i].sid == 'CENTER ICE')
                    ) {
                    sectionSide = MAP.SIDE.MIDDLE.ID;
                } else if(
                    ([6,7,17,18,523,524,529,530,307,308,309,310,322,323,324,325,711,712,713,732,733,734].includes(sectionNum)) ||
                    (['GARDENVIEW','SPORTSDECK','BOARDROOM','LOFTS'].includes(sectionName)) ||
                    (sectionName.includes('RAF SRO'))
                    ) {
                    sectionSide = MAP.SIDE.END.ID;
                } else if(
                    ([3,4,5,8,9,10,14,15,16,19,20,107,115,137,145,515,516,519,520,521,522,531,532,533,534,537,538,545,546,547,548,549,550,551,552,501,502,503,504,505,506,507,508,615,616,617,618,619,620,621,632,633,634,637,638,645,646,647,648,649,650,602,603,604,605,606,607,608,303,304,305,306,311,312,313,314,318,319,320,321,326,327,328,329,706,707,708,709,710,714,715,716,717,728,729,730,731,735,736,737,738,701].includes(sectionNum))
                    ) {
                    sectionSide = MAP.SIDE.CORNER.ID;
                }

                if(sectionName == HELPER.mapper(MAP.LEVEL, 'ID', 'PREFIX', levelName) + ' ' + data[i].sid) {
                    sectionName = MAP.SECTION.UNKNOWN.ID;
                }

                offers.push([
                    HELPER.toNumberIfNumber(data[i].id),
                    game[IDXG.id],
                    sectionSide,
                    levelName,
                    HELPER.toNumberIfNumber(data[i].sid),
                    HELPER.toNumberIfNumber(sectionName),
                    HELPER.toNumberIfNumber(data[i].r),
                    data[i].hasOwnProperty('sf') ? HELPER.toNumberIfNumber(data[i].sf) : 0,
                    data[i].hasOwnProperty('st') ? HELPER.toNumberIfNumber(data[i].st) : 0,
                    data[i].q,
                    (data[i].sp.length === 1 && data[i].sp[0] === data[i].q) ? 0 : data[i].sp, //qty split (0 = exact qty only)
                    data[i].p,
                    //note: data[i].n,
                    access,
                    //delivery_type_text: data[i].dt_text,
                    //disclosures: data[i].d
                ]);
            }
        }

        return offers;
    }

}